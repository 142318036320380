import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Col, Form, Modal } from "react-bootstrap"
import { acutionlist, getdata, imgurl } from '../Api/Api'
import { Link, useNavigate } from 'react-router-dom'
import smileemoji from "../assets/img/smile-emo.svg";
import logo from "../assets/img/logo.png"
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'

import Switch from "react-switch";
import moment from 'moment'

function AllProduct() {
    const [page, setpage] = useState("1")
    const [totalpage, settotalpage] = useState("")
    const [unitcost, setunitcost] = useState("")
    const navigate = useNavigate()
    const [maindata, setmaindata] = useState([])
    const [categorydata, setcategorydata] = useState([])
    const [cat, setcat] = useState("")
    const [userdata, setuserdata] = useState([])

    const [show, setShow] = useState(false);
    const [modalid, setmodalid] = useState("")
    const [startat, setstartat] = useState("")
    const [endat, setendat] = useState("")
    const [searchtitle, setsearchtitle] = useState("")
    const [searchbatchid, setsearchbatchid] = useState("")


    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserid, setSelectedUserid] = useState('');

    const [users, setUsers] = useState([]);

    useEffect(() => {


        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BaseUrl}/user?keyword=${searchTerm}`);
                setUsers(response.data.data); // Adjust based on API response format
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm == "" || selectedUser) {
            setUsers(userdata)

        }
    }, [searchTerm, selectedUser])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleUserSelect = (userId, userName) => {
        setSelectedUser(userName);
        setSelectedUserid(userId)

        setSearchTerm(''); // Optionally clear the search term
        setUsers([]); // Optionally clear users
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")
    const handlall = async (pageSize) => {
        setloading(true)
        const res = await getdata(`product?page=${pageSize}&rows=10`, headers)
        if (res.is_success == "1") {
            setloading(false)
            setdata(res?.data)
            setmaindata(res?.data)

            settotalpage(res.totalpages)
        }
        setpage(pageSize)


    }
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const [checked, setChecked] = useState(false);

    const handleChange = (id, item) => {
        console.log(item)
        setShow(true)
        // setChecked(checked);
        setunitcost(item?.unit_cost)
        setmodalid(id)
        setstartat(moment(item?.start_at).format("YYYY-MM-DDTHH:mm"))

        setendat(moment(item?.end_at).format("YYYY-MM-DDTHH:mm"))

    };

    const [loading, setloading] = useState(false)

    useEffect(() => {
        handlall(page)
        handlecategory()
        handleuser()
    }, [])




    const handleinfo = (e, id) => {
        e.preventDefault()
        // console.log(id)


        if (id.bidCount > 0) {
            navigate(`/allproduct/auction/${id._id}`)
        } else {
            toast.error("This product does not have any bids.");
        }
    }


    const handledelete = (del) => {
        setloading(true)
        let requestData = {
            data: {
                id: del._id
            },
            headers: headers
        };
        axios.delete(`${BaseUrl}/product/destroy`, requestData)
            .then((res) => {
                // console.log("ress", res)
                if (res?.data.is_success == "1") {
                    toast.success("Deleted Successfully");
                    handlall(page)
                }
            })
            .catch((error) => {
                console.error("Error deleting category:", error);
            }).finally(() => {
                setloading(false)
            })
    };





    const handleedit = (e, item) => {
        e.preventDefault()
        if (item.is_approved == false) {
            navigate(`/allproduct/editproduct/${item.url}`)
        } else {
            toast.error("You cannot edit this product  it is active.");
        }
    }

    const handlecategory = () => {
        axios.get(`${BaseUrl}/category`, { headers }).then((res) => {
            console.log(res)
            setcategorydata(res.data.data)
        })
    }


    const handlemodalsubmit = async (e) => {
        e.preventDefault()


        setloading(true)
        let requestData = {
            product_id: modalid
        }

        let senddata = {
            product_id: modalid,
            unit_cost: unitcost,
            start_at: startat,
            end_at: endat,
        };



        await axios.put(`${BaseUrl}/product/update`, senddata, { headers })
            .then((res) => {


            })
            .catch((error) => {
                console.error("Error:", error);

            })

        await axios.post(`${BaseUrl}/admin/product_approval_change`, requestData, { headers }).then((res) => {
            if (res?.data?.data != "") {
                toast.success("Succesffully Update")
                handlall(page)
                setShow(false)

            }
        }).finally(() => {
            setloading(false)
        })





    }



    const handlesearch = (e) => {
        e.preventDefault();

        // Dynamically build the query string
        let query = "";

        if (searchtitle) {
            query += `title=${searchtitle}&`;
        }
        if (selectedUser) {
            query += `user_id=${selectedUserid}&`;
        }
        if (searchbatchid) {
            query += `batch_id=${searchbatchid}&`;
        }
        if (cat) {
            query += `category_id=${cat}&`;
        }

        // Remove the trailing '&' if it exists
        query = query.slice(-1) === "&" ? query.slice(0, -1) : query;

        // Make the API request with the dynamic query
        axios.get(`${BaseUrl}/product?${query}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Ensure token is defined and valid
            }
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.is_success == "1") {
                    setdata(res.data.data); // Assuming res.data.data contains the filtered data
                } else {
                    toast.error('No data found');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error('Error fetching data');
            });
    };

    const handleuser = () => {
        axios.get(`${BaseUrl}/user`, { headers }).then((res) => {

            setuserdata(res.data.data)
        })
    }

    const handleclear = (e) => {
        e.preventDefault()
        setdata(maindata)
        setSelectedUser("")
        setsearchbatchid("")
        setsearchtitle("")
    }

    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="row mt-3 tableview align displaynonepagination gy-3">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center gap-3 mb-3">
                                <button className='btn bg-dark text-white' disabled={page == 1} onClick={(e) => handlall(parseInt(page) - 1)}><i className="fa-solid fa-angle-left"></i></button>
                                <span>{page} / {totalpage}</span>
                                <button className='btn bg-dark text-white' disabled={page >= totalpage} onClick={(e) => handlall(parseInt(page) + 1)}><i className="fa-solid fa-angle-right"></i></button>
                            </div>

                        </div>
                        <div className="col-md-2">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle d-flex align-items-center justify-content-between bg-white rounded-pill shadow-none border-0 w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedUser || 'Select User'}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="px-2 mt-2">
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            className="form-control shadow-none "
                                        />
                                    </li>
                                    {users.map((item) => (
                                        <li key={item._id} style={{ cursor: "pointer", }} className="dropdown-item " onClick={() => handleUserSelect(item._id, item.name)}>
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>


                        </div>
                        <div className="col-md-3">

                            <div className="searchbox rounded-pill input-group w-100 border-0">
                                <i class="fa-solid fa-magnifying-glass"></i>
                                <input type="search" className='form-control shadow-none border-0 rounded-pill ' value={searchbatchid} onChange={(e) => setsearchbatchid(e.target.value)} placeholder='Search by  Batch Id' />
                            </div>

                        </div>
                        <div className="col-md-2">

                            <div className="searchbox rounded-pill input-group w-100 border-0">
                                <i class="fa-solid fa-magnifying-glass"></i>
                                <input type="search" className='form-control shadow-none border-0 rounded-pill ' value={searchtitle} onChange={(e) => setsearchtitle(e.target.value)} placeholder='Search by  Title' />
                            </div>

                        </div>
                        <div className="col-md-2">

                            <div className="searchbox rounded-pill input-group w-100 border-0">
                                <select name="" value={cat} onChange={(e) => setcat(e.target.value)} className='form-control form-select shadow-none' id="">
                                    <option value="">Select Category</option>
                                    {categorydata?.map((item) => {
                                        return (
                                            <>
                                                <option value={item._id}>{item.title}</option>

                                            </>
                                        )
                                    })}
                                </select>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <button onClick={handlesearch} className='btn bg-primary text-white'>Search</button>
                            <button onClick={(e) => handleclear(e)} className='btn bg-primary text-white ms-2'>Clear Filters</button>

                        </div>

                        <div className="col-md-12">
                            <div className="">
                                {/* <Box >
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        pageSize={data.length} // Set the page size to the total number of rows
                                        pagination={false}     // Disable pagination
                                    />
                                </Box> */}
                                <div className="table-responsive tableview customtable">
                                    <table className="table">
                                        <thead>
                                            <tr>

                                                <th>Images</th>
                                                <th>User</th>
                                                <th>Batch Id</th>
                                                <th>Title</th>
                                                <th>Quantity</th>
                                                <th>Unit Cost</th>
                                                {/* <th>Bidding Price</th> */}
                                                <th>View Bid</th>
                                                <th>Change Status</th>
                                                <th>Is Active</th>
                                                <th>Winning User</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((item) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td><img src={`${imgurl}/${item.images?.[0]?.image}`} onError={(e) => {
                                                                e.target.onerror = null; // prevent infinite loop
                                                                e.target.src = logo; // replace with your error image URL
                                                            }} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} /></td>
                                                            <td>
                                                                <ul className='list-unstyled mb-0 '>
                                                                    <li> <b>Name : </b>
                                                                        {item?.user_id?.name}</li>

                                                                    <li > <b>Email : </b>{item?.user_id?.email}</li>
                                                                    <li > <b>Mobile : </b>{item?.user_id.contact?.mobile}</li>
                                                                    <li > <b>WhatsApp : </b>{item?.user_id.contact?.whatsapp}</li>


                                                                </ul>
                                                            </td>
                                                            <td>
                                                                {item?.batch_id?.batch_id ?? item?.batch_id}
                                                            </td>
                                                            <td>
                                                                <ul className='list-unstyled mb-0 '>
                                                                    <li> <b>Product : </b>
                                                                        {item?.title}</li>

                                                                    <li > <b>Category : </b>{item.category_id?.title}</li>

                                                                </ul>
                                                            </td>
                                                            <td>{item.quantity?.toFixed(2)} {item?.unit} </td>
                                                            <td>
                                                                {item.unit_cost
                                                                    ? <>
                                                                        <span>&#8377;</span>{item?.unit_cost}
                                                                    </>
                                                                    : ""
                                                                }
                                                            </td>
                                                            {/* <td>&#8377;{(item.quantity * item.subcategory_id?.rate)?.toFixed(2)}</td> */}
                                                            <td>
                                                                <div className="position-relative" style={{ width: "max-content" }}>

                                                                    <p className=' text-white p-2 mb-0 bidnotification'>{item.bidCount > 10 ? `10+` : item.bidCount}</p>

                                                                    <button className='btn custombtn' onClick={(e) => handleinfo(e, item)} ><i className="fa-solid fa-circle-info"></i></button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <ul className='list-unstyled mb-0 '>
                                                                    <li><b>Product Start at</b> : {moment(item.start_at).format("YYYY-MM-DD ")}</li>
                                                                    <li><b>Product End at</b> : {moment(item.end_at).format("YYYY-MM-DD ")}</li>
                                                                    <li>
                                                                        Biding Status :
                                                                        <span className={''}>
                                                                            {
                                                                                moment().format('YYYY-MM-DD') > moment(item.end_at).format("YYYY-MM-DD ") || item.is_completed == "Completed" ? 'Time Closed' : "Active"
                                                                            }
                                                                        </span>


                                                                    </li>
                                                                    <li>
                                                                        Current Status : {item.is_completed}
                                                                    </li>


                                                                </ul>
                                                            </td>
                                                            <td><Switch onChange={(e) => handleChange(item._id, item)} checked={item.is_approved} /></td>
                                                            <td>{item.winner_id?.name}</td>
                                                            <td>
                                                                <button className='btn custombtn me-2' onClick={(e) => handleedit(e, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                                                <button className='btn custombtn' onClick={(e) => handledelete(item)}><i className="fa-solid fa-trash"></i></button></td>

                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Product Active</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handlemodalsubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="">Minimum Amount</label>
                                    <input type="text" value={unitcost} onChange={(e) => setunitcost(e.target.value)} className='form-control' />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor=""> Start Date</label>
                                    <input type="datetime-local" value={startat} onChange={(e) => setstartat(e.target.value)} className='form-control shadow-none' />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="">End Date</label>
                                    <input type="datetime-local" value={endat} onChange={(e) => setendat(e.target.value)} className='form-control shadow-none' />
                                </div>
                                <button className='btn bg-green text-white px-5'>Submit</button>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </>} />
        </>
    )
}

export default AllProduct






