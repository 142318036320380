import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Form, FormGroup } from "react-bootstrap"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

import { BaseUrl } from '../Url'
import { ColorRing } from 'react-loader-spinner';
import Loader from '../Components/Loader';

function Category() {
    const [loading, setloading] = useState(false)
    const [title, settitle] = useState("")
    const [description, setdescription] = useState("")
    const [data, setdata] = useState([])
    const [editid, seteditid] = useState("")
    const [file, setfile] = useState("")
    // const token = localStorage.getItem("token")
    // const headers = {
    //     Authorization: `Bearer ${token}`
    // }
    const getRowId = (row) => row._id
    const handleSubmit = (e) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            title: title,
            description: description,
        }
        if (!editid) {
            axios.post(`${BaseUrl}/category`, requestdata).then((res) => {
                // console.log("Res", res)
                if (res.data.is_success == "1") {
                    // toast.success("Data Submitted Successfully")
                    handleclear()
                    handleget()
                    handleimgagesubmit(res.data.data._id)
                }

            }).finally(() => {
                setloading(false)
            })
        } else {

            requestdata._id = editid
            axios.put(`${BaseUrl}/category`, requestdata).then((res) => {
                // console.log("Res", res)
                if (res.data.is_success == "1") {

                    toast.success("Data Updated Successfully")
                    handleclear()
                    handleget()
                    handleimgagesubmit(editid)
                }

            }).finally(() => {
                setloading(false)
            })
        }
    }

    console.log(editid)
    const handleimgagesubmit = (imageid) => {
        let formdata = new FormData()
        formdata.append("category_id", imageid)
        formdata.append("icon", file)
        axios.post(`${BaseUrl}/category/set-icon`, formdata).then((res) => {
            if (res.data.is_success == "1") {
                toast.success("Data Submit Succesffully")
            }
        })
    }


    const handleclear = () => {
        settitle("")
        setdescription("")
    }
    const handleget = (e) => {
        axios.get(`${BaseUrl}/category`).then((res) => {
            setdata(res.data.data)
        })
    }
    useEffect(() => {
        handleget()
    }, [])
    const columns = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,

        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => handleedit(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>
                    </>
                )
            }

        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => handledelete(params.row)}><i className="fa-solid fa-trash"></i></button>
                    </>
                )
            }

        }
    ]

    const handleedit = (editdata) => {
        console.log(editid)
        seteditid(editdata._id)
        settitle(editdata.title)
        setdescription(editdata.description)
    }

    const handlefile = (e) => {
        let selectedfile = e.target.files[0]
        setfile(selectedfile)
    }

    // const handledelete = (del) => {
    //      // console.log("delet", del._id)
    //     let requestdata = {
    //         id: del._id
    //     }

    //     axios.delete(`${BaseUrl}/category/destroy`, requestdata).then((res) => {
    //         if (res.data.is_success == "1") {
    //             toast.success("Deleted Succesffully")
    //             handleget()
    //         }
    //     })

    // }
    const handledelete = (del) => {
        setloading(true)
        let requestData = {
            data: {
                id: del._id
            }
        };

        axios.delete(`${BaseUrl}/category/destroy`, requestData)
            .then((res) => {
                // console.log("ress", res)
                if (res.data.is_success == "1") {
                    toast.success("Deleted Successfully");
                    handleget(); // Assuming handleGet is defined elsewhere to refresh the category list
                }
            })
            .catch((error) => {
                console.error("Error deleting category:", error);
            }).finally(() => {
                setloading(false)
            })
    };



    return (
        <>
            {loading &&
                <Loader />
            }
            <ToastContainer />
            <Sidebaar content={<>
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3>Category</h3>
                                </div>
                            </div>

                        </div>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Title</label>
                                        <input type="text" value={title} onChange={(e) => settitle(e.target.value)} className='form-control' />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Description</label>
                                        <input type="text" value={description} onChange={(e) => setdescription(e.target.value)} className='form-control' />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Image</label>
                                        <input type="file" onChange={(e) => handlefile(e)} className='form-control' />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4">
                                    <button className='btn mt-3 bg-green text-white'>Submit</button>
                                </div>

                            </div>
                        </Form>
                        <div className="row mt-3 tableview">
                            <div className="col-md-12">
                                <Box sx={{ height: 500, width: "100%" }}>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        pageSize={5}
                                        getRowId={getRowId}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </section>
            </>} />
        </>
    )
}

export default Category
