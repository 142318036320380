import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import TitleComp from '../Components/TitleComp'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { toast } from 'react-toastify'

function PushNotifications() {
    const [title, setTitle] = useState('');
    const [user, setUser] = useState('');
    const [specefic, setspecefic] = useState('');

    const [data, setdata] = useState([])
    const [message, setMessage] = useState('');

    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`
    };

    const handleget = () => {
        axios.get(`${BaseUrl}/user`, { headers }).then((res) => {

            setdata(res.data.data)
        })
    }

    useEffect(() => {
        handleget()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        let requestData = {
            title,
            message
        };


        if (user !== 'All') {
            requestData.user_type = user;
        }
        if (user == 'speificuser') {
            requestData.user_id = specefic;
        }
        axios.post(`${BaseUrl}/admin/send_notificaiton_to_user`, requestData, { headers })
            .then((res) => {
                if (res.data) {
                    toast.success("Message Sent Successfully")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <>
            <Sidebar content={
                <>
                    <section>
                        <div className="container-fluid">
                            <Form onSubmit={handleSubmit}>
                                <div className="row gy-3">
                                    <div className="col-md-12">
                                        <TitleComp title="Push Notifications" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className='form-control shadow-none'
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="user">User</label>
                                        <select
                                            className="form-control form-select shadow-none"
                                            value={user}
                                            onChange={(e) => setUser(e.target.value)}
                                        >
                                            <option value="">Select User</option>
                                            <option value="isBuyer"> Buyer</option>
                                            <option value="isSeller">Seller</option>
                                            <option value="All">All</option>
                                            <option value="speificuser">Specific User</option>

                                        </select>
                                    </div>
                                    {user == "speificuser" && <div className="col-md-4">
                                        <label htmlFor="user">Specific User</label>
                                        <select
                                            className="form-control form-select shadow-none"
                                            value={specefic}
                                            onChange={(e) => setspecefic(e.target.value)}
                                        >
                                            <option value="">Select User</option>
                                            {data.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.name}</option>

                                                    </>
                                                )
                                            })}

                                        </select>
                                    </div>}

                                    <div className="col-md-12">
                                        <label htmlFor="message">Message</label>
                                        <textarea
                                            className='form-control shadow-none'
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <button className='btn  bg-green text-white' type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </section>
                </>
            } />
        </>
    );
}

export default PushNotifications;
