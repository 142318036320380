import React, { useEffect, useState } from 'react'
import { post, postdata, putdata } from '../Api/Api'
import Sidebaar from '../Components/Sidebar'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import profile from "../assets/img/profile.png"
import { BaseUrl } from '../Url'
import moment from 'moment'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'

function AuctionData() {
    const [auctiondata, setauctiondata] = useState([])
    const { id } = useParams()
    const [loading, setloading] = useState(false)

    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const getauctiondata = async () => {
        const res = await postdata("auction/get-auction-by-product", id, headers).then((res) => {
            // console.log(res.data)
            setauctiondata(res.data)
        })
    }

    useEffect(() => {
        getauctiondata()
    }, [])

    const getRowId = (row) => row._id

    const columns = [
        {
            field: "bid_amount",
            headerName: "Bid Amount",
            flex: 1

        },
        {
            field: "amount",
            headerName: " Amount",
            flex: 1

        },
        {
            field: "bid_fee",
            headerName: " Bid Fee",
            flex: 1

        }
    ]



    const statuses = [

        {
            status: "pending",
            bg: "bg-warning text-white"
        },
        {
            status: "closed",
            bg: "bg-success text-white"
        },
        {
            status: "cancelled",
            bg: "bg-danger text-white"
        },
        {
            status: "new",
            bg: "bg-warning text-white"
        },
        {
            status: "accepted",
            bg: "bg-info text-white"
        },
        {
            status: "completed",
            bg: "bg-success text-white"
        }
    ];



    const handleWin = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await putdata(`auction/make-winner`, requestdata, headers)

        if (res.is_success == "1") {

            getauctiondata()
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }
    const handleAccept = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await post(`auction/accept`, requestdata, headers)

        if (res.is_success == "1") {

            getauctiondata()
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }



    const handleCancel = async (e, bidid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            bid_id: bidid
        }
        const res = await post(`auction/cancell-auction`, requestdata, headers)
        if (res.errors) {
            res.errors.map((item) => {
                return toast.error(`${item.msg} in ${item.path}`)
            })
        }
        if (res.is_success == "1") {

            getauctiondata()
            setTimeout(() => {
                setloading(false)
                toast.success(res.message)
            }, 600);
        } else {
            toast.error(res.message)
            setloading(false)
        }
    }

    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={<>
                <div className="col-md-12">
                    {/* <Box>
                        <DataGrid
                            rows={auctiondata}
                            columns={columns}
                            pageSize={1}
                            getRowId={getRowId}

                        />
                    </Box> */}
                    <div className="table-responsive tableview customtable">
                        <table className="table">
                            <thead>
                                <tr >

                                    <th>User</th>
                                    <th>Product</th>
                                    <th>Bid Quantity</th>
                                    <th>Bid Amount</th>
                                    <th>Market Value</th>
                                    <th>
                                        Bid Dates
                                    </th>
                                    <th>Bid Product Status</th>
                                    <th>Is Winner</th>
                                    <th>Bid Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auctiondata.map((item) => {
                                    return (
                                        <>
                                            <tr>

                                                <td>
                                                    <ul className='list-unstyled mb-0 '>
                                                        <li > <b>Bid Id : </b>{item.bid_order_id}</li>
                                                        <li> <b>Name : </b>
                                                            {item.user_id.name}</li>

                                                        <li > <b>Email : </b>{item.user_id.email}</li>
                                                        <li > <b>Mobile : </b>{item.user_id.contact.mobile}</li>
                                                        <li > <b>Whatsapp : </b>{item.user_id.contact.whatsapp}</li>
                                                        <li > <b>Userid : </b>{item.user_id._id}</li>

                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul className='list-unstyled mb-0 '>
                                                        <li> <b>Product : </b>
                                                            {item.product_id.title}</li>


                                                    </ul>
                                                </td>
                                                <td>{item.quantity?.toFixed(2)}  <span>{item.product_id.unit}</span></td>
                                                <td>&#8377; {item.bid_amount?.toFixed(2)} </td>

                                                <td>&#8377;{(item.product_id.quantity * item.product_id.unit_cost)?.toFixed(2)} </td>
                                                <td>
                                                    <ul className='list-unstyled mb-0 '>
                                                        <li><b>Bid Start at</b> : {moment(item.product_id.start_at).format("YYYY-MM-DD ")}</li>
                                                        <li><b>Bid End at</b> : {moment(item.product_id.end_at).format("YYYY-MM-DD ")}</li>
                                                        <li><b>Bid Create at</b> : {moment(item.bid_date).format("YYYY-MM-DD ")}</li>

                                                    </ul>
                                                </td>
                                                <td>
                                                    <span className={`badge px-3 rounded-pill 
                                                                ${statuses.find(obj => obj.status == item.product_id.is_completed.toLowerCase())?.bg}`}>
                                                        {item.product_id.is_completed}
                                                    </span>
                                                </td>
                                                <td>
                                                    {item.status == "pending" ? <span className='badge bg-warning text-white px-3 rounded-pill'>Pending</span> : item.is_win ? <span className='badge bg-success text-white px-3 rounded-pill'>Winner</span> : <span className='badge bg-danger text-white px-3 rounded-pill'>No</span>}
                                                </td>
                                                <td>
                                                    <span className={`badge px-3 rounded-pill 
                                                                ${statuses.find(obj => obj.status == item.status.toLowerCase())?.bg}`}>
                                                        {item.status}
                                                    </span>
                                                </td>

                                                <td>
                                                    {item.status != "completed" && item.status != "closed" ? <div className="dropdown">
                                                        <button className="btn custombtn dropdown-toggle p-0 px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Action
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            {item.status == "accepted" ? <li><button className="dropdown-item btn" href="#" onClick={(e) => handleWin(e, item._id)}>Make Win</button></li>
                                                                : item.status == "New" || item.status == "pending" ? <>
                                                                    <li onClick={(e) => handleAccept(e, item._id)}><button className="dropdown-item btn">Accept Now</button></li>
                                                                    <li onClick={(e) => handleCancel(e, item._id)}><button className="dropdown-item btn" >Cancel Now</button></li>
                                                                </> : ""}
                                                        </ul>
                                                    </div> : <span className={`badge px-3 rounded-pill bg-success text-white`}>
                                                        {item.status}
                                                    </span>}
                                                </td>


                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* {auctiondata.map((item) => {
                    return (
                        <>
                            <div className="col-md-4">
                                <div className="bg-white shadow p-3 userbox card1">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className="text-end">
                                                <button className='btn hoverbtn help-tip'><i className="fa-solid fa-circle-info"></i></button>
                                                <button className='btn hoverbtn'><i className="fa-solid fa-house-user"></i></button>
                                            </div>

                                        </div>
                                        <div className="col-md-2 pe-0">
                                            <img
                                                src={`${BaseUrl}/${item.user_id.profile_image}`}
                                                onError={(e) => {
                                                    e.target.src = profile;
                                                    e.target.onerror = null;
                                                }}
                                                className='img-fluid profileimage'
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-10 ">
                                            <div className=" boxcontent">
                                                <h5>Name : <span>{item.user_id.name}</span></h5>
                                                <h5>Mobile : <span>{item?.user_id?.contact?.mobile}</span></h5>
                                            </div>

                                            <div className="boxcontent">
                                                <h5>Bid Amount : <span>{item?.bid_amount}</span></h5>
                                                <h5>Amount : <span>{item?.amount}</span></h5>
                                            </div>
                                            <div className="boxcontent">
                                                {item?.user_id?.business_name && <h5>Business Name : <span>{item?.user_id?.business_name}</span></h5>}
                                                <h5>email : <span>{item?.user_id?.email}</span></h5>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>






                        </>
                    )
                })} */}
            </>} />
        </>
    )
}

export default AuctionData
