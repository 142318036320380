import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import Select from 'react-select';
import TitleComp from '../Components/TitleComp';
import axios from "axios"
import { BaseUrl } from '../Url';
import { FormGroup, Form } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import Loader from '../Components/Loader';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

function SubCategories() {
    const [selectedOption, setselectedOption] = useState("")
    const [searchdata, setsearchdata] = useState("")
    const [data, setdata] = useState("")
    const [title, settitle] = useState("")
    const [unit, setunit] = useState("")
    const [rate, setrate] = useState("")
    const [loading, setloading] = useState(false)
    const [search, setsearch] = useState("")

    const [editid, seteditid] = useState("")

    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }


    const handleget = () => {
        axios.get(`${BaseUrl}/category`).then((res) => {
            setdata(res.data.data)
            // console.log(res.data.data)
        })
    }

    const handleSelect = (selectedOption) => {
        setselectedOption(selectedOption);
    };

    const handleSearch = (selectedsearch) => {
        setsearch(selectedsearch);
        // console.log(selectedsearch)
        let requestdata = {
            category_id: selectedsearch.value
        }
        axios.post(`${BaseUrl}/subcategory/get`, requestdata).then((res) => {
            setsearchdata(res.data.data.sub_category)
            // console.log("responoseone", res.data.data.sub_category)
        })
    };

    useEffect(() => {
        handleget()
    }, [])

    useEffect(() => {
        if (data) {
            handleSearch({
                value: data[0]?._id,
                label: data[0]?.title
            })
        }
    }, [data])


    const options = data && data?.map((item) => ({
        value: item?._id,
        label: item?.title,
    }));


    const getRowId = (row) => row._id

    const handleSubmit = (e) => {
        setloading(true)
        e.preventDefault()
        let requestdata = {
            category_id: selectedOption?.value,
            rate: rate,
            unit: unit,
            title: title
        }
        if (!editid) {
            axios.post(`${BaseUrl}/subcategory`, requestdata, { headers }).then((res) => {
                // console.log(res)
                if (res.data.is_success == "1") {
                    toast.success("Data Submitted Successfully")
                    handleclear()
                    handleget()
                }

            }).finally(() => {
                setloading(false)
            })
        } else {
            requestdata.subcategory_id = editid
            axios.put(`${BaseUrl}/subcategory`, requestdata, { headers }).then((res) => {
                // console.log(res)
                if (res.data.is_success == "1") {
                    toast.success("Data Update Successfully")
                    handleclear()
                    handleget()
                }

            }).finally(() => {
                setloading(false)
            })
        }
    }
    const handleclear = () => {
        setselectedOption([])
        settitle("")
        setrate("")
        setunit("")
    }


    const columns = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },
        {
            field: "rate",
            headerName: "Rate",
            flex: 1,

        },
        {
            field: "unit",
            headerName: "Unit",
            flex: 1,
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => handleedit(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>
                    </>
                )
            }

        }
    ]

    // const { title, rate, unit, category_id, subcategory_id } = req.body;
    const handleedit = (editdata) => {

        seteditid(editdata?._id)
        setselectedOption({
            value: editdata?.category_id._id,
            label: editdata?.category_id.title,
        })
        console.log(editdata)
        settitle(editdata.title)
        setunit(editdata.unit)
        setrate(editdata.rate)

    }


    return (
        <>
            {loading &&
                <Loader />
            }

            <Sidebaar content={<>
                <section>
                    <div className="container">
                        <div className="row mb-3">
                            <TitleComp title="Sub Categories" />

                        </div>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row gy-3">
                                <div className="col-md-4">
                                    <label htmlFor="">Select Catogries</label>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleSelect}
                                        options={options}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Title</label>
                                        <input type="text" value={title} onChange={(e) => settitle(e.target.value)} className='form-control' />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Rate</label>
                                        <input type="text" value={rate} onChange={(e) => setrate(e.target.value)} className='form-control' />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup>
                                        <label htmlFor="">Unit</label>
                                        <input type="text" value={unit} onChange={(e) => setunit(e.target.value)} className='form-control' />
                                    </FormGroup>
                                </div>
                                <div className="col-md-12">
                                    <button className='btn bg-green text-white' >Submit</button>
                                </div>
                            </div>
                        </Form>

                        <div className="row mt-3 tableview">
                            <div className="col-md-4">
                                <div className=" mb-3">
                                    <label htmlFor="">Search CategoryWise</label>
                                    <Select
                                        value={search}
                                        onChange={handleSearch}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                {searchdata && <Box sx={{ height: 500, width: "100%" }}>
                                    <DataGrid
                                        rows={searchdata}
                                        columns={columns}
                                        pageSize={5}
                                        getRowId={getRowId}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </Box>}
                            </div>
                        </div>
                    </div>
                </section>
            </>} />
        </>
    )
}

export default SubCategories
