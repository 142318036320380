import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Form } from 'react-bootstrap'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios';
import { getdata, post, postdata } from '../Api/Api';
import { toast } from 'react-toastify';

function Policy() {
    const [policy, setpolicy] = useState("")
    const [newpolicy, setnewpolicy] = useState(false)
    const [desc, setdesc] = useState("")
    const [policydata, setpolicydata] = useState([])
    const [title, settitle] = useState("")

    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };

    useEffect(() => {
        if (policy == "create") {
            setnewpolicy(true)
            setdesc("")

        } else {
            setnewpolicy(false)
        }
    }, [policy])


    const handlepolicy = async () => {
        const res = await getdata(`policy`, headers)
        setpolicydata(res.data)
    }

    const handlefetch = async (e) => {
        let value = e.target.value

        let checkres = policydata.filter((item) => item._id == value)


        if (value != "create") {
            const res = await getdata(`policy/by-url/${checkres[0]?.url}`, headers)
            setdesc(res.data.description)
            settitle(res.data.title)
        } else {
            settitle("")
            setdesc("")
        }
    }

    useEffect(() => {
        handlepolicy()
    }, [])

    const handlepost = async (e) => {
        e.preventDefault()
        if (policy == "create") {
            let requestdata = {

                title: title,
                description: desc
            }
            let res = await post(`policy`, requestdata, headers)
            if (res.is_success = "1") {
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        } else {
            let requestdata = {
                policy_id: policy,
                title: title,
                description: desc
            }
            let res = await post(`policy`, requestdata, headers)
            // console.log(res)
            if (res.is_success = "1") {
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        }
    }
    return (
        <>
            <Sidebaar content={
                <>
                    <Form onSubmit={(e) => handlepost(e)}>
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="">Select Policy</label>
                                <select name="" value={policy} onChange={(e) => { setpolicy(e.target.value); handlefetch(e) }} className='form-control form-select  shadow-none' id="">
                                    <option value="">---Select---</option>
                                    {policydata.map((item) => {
                                        return (
                                            <>
                                                <option value={item._id}>{item.title}</option>
                                            </>
                                        )
                                    })}
                                    <option value="create">Create New Policy</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Policy Title</label>
                                <input type="text" value={title} onChange={(e) => settitle(e.target.value)} className='form-control shadow-none' />
                            </div>
                            <div className="col-md-12">
                                <div className="mt-3">
                                    <label htmlFor="">Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={desc}
                                        onChange={handleEditorChange}
                                        className="custom-ckeditor"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className='btn mt-3 bg-green text-white'>Submit</button>
                            </div>
                        </div>
                    </Form>
                </>
            } />
        </>
    )
}

export default Policy
