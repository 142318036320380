import React, { useEffect, useState } from 'react'
import loginbox from "../assets/img/loginbox.jpg"
import logo from "../assets/img/logo.png"
import { Form, FormGroup } from 'react-bootstrap'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Loader from '../Components/Loader'
function Login() {
    const [show, setshow] = useState(false)
    const [loading, setloading] = useState(false)
    const [email, setemail] = useState("")
    const navigate = useNavigate()
    const [password, setpassword] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        setloading(true)
        // setshow(true)
        let requestdata = {
            email: email,
            password: password
        }
        axios.post(`${BaseUrl}/admin/login`, requestdata).then((res) => {
            setloading(false)
            if (res.data.is_success == 1) {
                toast.success(res.data.message)
                localStorage.setItem("token", res.data.data)
                localStorage.setItem("env", res.data.environment)
                if (res.data.environment == "dev") {
                    localStorage.setItem("ischecked", true)
                } else {
                    localStorage.setItem("ischecked", false)
                }

                navigate('/home')
            } else {

                toast.error(res.data.message)
            }
        }).catch((err) => {

        }).finally(() => {
            setloading(false)
        })
    }
    const handleviewchange = (e) => {
        e.preventDefault()
        setshow(!show)
    }

    useEffect(() => {
        localStorage.clear()
    }, [])


    return (
        <>
            <ToastContainer />
            {loading && <Loader />}
            <section className='loginpage'>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8">
                            <div className="row loginbox p-0 rounded-4">
                                <div className="col-md-6 p-0">
                                    <div className="">
                                        <img src={loginbox} className='img-fluid rounded-4' alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="logincredential">
                                        <div className="text-center">
                                            <img src={logo} className='img-fluid' style={{ width: "100px" }} alt="" />
                                        </div>
                                        <Form className='mt-4' onSubmit={(e) => handleSubmit(e)}>
                                            <FormGroup className='mb-3'>
                                                <label htmlFor="">Email</label>
                                                <input type="text" value={email} onChange={(e) => setemail(e.target.value)} placeholder='Email' className='form-control shadow-none' />
                                            </FormGroup>
                                            <FormGroup className='mb-3 '>
                                                <label htmlFor="">Password</label>
                                                <div className="input-group">
                                                    <input type={!show ? "password" : "text"} value={password} onChange={(e) => setpassword(e.target.value)} placeholder='Password' className='form-control shadow-none' />
                                                    <span className='mt-2 ' onClick={(e) => handleviewchange(e)}>{!show ? <i className="fa-solid fa-eye px-2"></i> : <i className="fa-solid fa-eye-slash px-2"></i>}</span>
                                                </div>
                                            </FormGroup>
                                            <div className="text-center mt-5">
                                                <button className='btn'>Submit</button>
                                            </div>


                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login


