
import React, { useEffect, useRef, useState } from 'react';


import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { BaseUrl } from '../Url';
import Loader from '../Components/Loader';
import { get_states } from '../Api/Api';
import Sidebaar from '../Components/Sidebar';
import TitleComp from '../Components/TitleComp';


const AddSeller = () => {
    const navigate = useNavigate();

    const [states, setStates] = useState([]);
    const [fdata, setFdata] = useState({});
    const [location, setLocation] = useState({});
    const [documents, setDocuments] = useState({});
    const [contact, setContact] = useState({ "country_code": "+91" });
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState("");
    const [is_success, setIsSuccess] = useState(false);
    const [slide, setSlide] = useState(1);
    const [loading, setLoading] = useState(false);
    const [name, setname] = useState("")
    const [businessname, setbusinessname] = useState("")
    const [email, setemail] = useState("")
    const [mobile, setmobile] = useState("")
    const [password, setpassword] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [pincode, setpincode] = useState("")
    const [address, setaddress] = useState("")
    const [pannumber, setpannumber] = useState("")
    const [incorpdate, setincorpdate] = useState("")
    const [gstnumber, setgstnumber] = useState("")
    const [bankname, setbankname] = useState("")
    const [accountnumber, setaccountnumber] = useState("")
    const [ifsc, setifsc] = useState("")

    const [panimage, setpanimage] = useState("")
    const [profileimage, setprofileimage] = useState("")
    const [doi, setdoi] = useState("")
    const [gst_image, setgst_image] = useState("")
    const [show, setShow] = useState(false);







    const handleProfile = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        console.log(selectedfile)
        setprofileimage(selectedfile)
    }

    const handlepanimage = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setpanimage(selectedfile)
    }


    const handleincorporationdoc = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setdoi(selectedfile)
    }



    const handlegstdocument = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setgst_image(selectedfile)
    }





    const nextSlide = () => {




        let hasError = false;

        if (name == "") {
            toast.error("Name is required.");
            hasError = true;
        }
        if (businessname == "") {
            toast.error("Business Name is required.");
            hasError = true;

        }
        if (email == "") {
            toast.error("Email is required.");
            hasError = true;
        }
        if (mobile === "" || mobile.length < 10) {
            toast.error("Please enter a valid 10-digit mobile number.");
            hasError = true;
        }
        if (password == "") {
            toast.error("Password is required.");
            hasError = true;
        }

        if (!hasError) {
            setSlide(prev => prev + 1);
        }



    }




    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const submitdata = async () => {

        let hasError = false
        setLoading(true)



        if (!panRegex.test(pannumber.toUpperCase())) {
            toast.error("Invalid PAN Number");
            hasError = true
        }

        let requestdata = {

            name: name,
            business_name: businessname,
            email: email,
            contact: {
                country_code: "+91",
                mobile: mobile,
                whatsapp: mobile,
            },
            address: {
                address: address,
                city: city,
                state: state,
                pincode: pincode
            },
            documents: {
                pancard: {
                    number: pannumber.toUpperCase(),

                },
                bank: {
                    bank_name: bankname,

                    account_no: accountnumber,
                    ifsc: ifsc,

                },
                incorporation_certificate: {
                    date_of_incorporation: incorpdate,

                },
                gst_certificate: {
                    number: gstnumber,

                }
            },
            login_type: {
                role: "User",
                isBuyer: false,
                isSeller: true
            },
            password: password,
            core_password: password,

        }


        if (!hasError) {
            axios.post(`${BaseUrl}/user`, requestdata).then((res) => {
                // console.log(res.data.errors)
                if (res.data.errors) {
                    res.data.errors.map((item) => {
                        toast.error(item.msg)
                    })
                }
                if (res.data.is_success == "1") {
                    updateimage(res.data.temp_token)

                }
            }).catch((err) => {
                console.log(err)

            }).finally(() => {
                setLoading(false)
            })
        }


    }

    const updateimage = async (imageToken) => {
        let formData = new FormData();
        setLoading(true);
        formData.append("panimage", panimage);
        formData.append("profile_image", profileimage);
        formData.append("doi", doi);
        formData.append("gst_image", gst_image);

        axios.post(`${BaseUrl}/user/upload-images`, formData, {
            headers: {
                Authorization: `Bearer ${imageToken}`
            }
        }).then((res) => {
            if (res.data.is_success == 1) {

                navigate("/alluser")

            }
        }).catch((error) => {
            console.error("Error:", error);
            // Handle error, show error message or take appropriate action
        });
    }

    const getstates = async () => {
        const resp = await get_states();
        setStates(resp.data)
    }
    useEffect(() => {
        getstates();
    }, []);

    const [currActive, setCurrActive] = useState(0);





    const backSlide = () => {
        setSlide(prev => prev - 1)
    }

    return (
        <>




            <div className="col-md-12 position-relative">
                {
                    loading && (
                        <>
                            <Loader />
                        </>
                    )
                }



                <Sidebaar content={
                    <>
                        <TitleComp title="Add Seller" />
                        {
                            slide == 1 && (
                                <>
                                    <div className="content form-wrapper animate__animated animate__backInLeft ">
                                        <div className="row ">

                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Your Full Name <span className='text-danger'>*</span></label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={name} onChange={(e) => setname(e.target.value)} name='name' />
                                                    {/* <span className="text-danger">
                                                {errors.find(obj => obj.path == "name")?.msg}
                                            </span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Your Business Name <span className='text-danger'>*</span></label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={businessname} onChange={(e) => setbusinessname(e.target.value)} name='business_name' />
                                                    {/* <span className="text-danger">
                                                {errors.find(obj => obj.path == "business_name")?.msg}
                                            </span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Profile Image </label>
                                                    <input className='form-control shadow-none mb-3' type="file" onChange={(e) => handleProfile(e)} name='business_name' />
                                                    {/* <span className="text-danger">
                                                {errors.find(obj => obj.path == "business_name")?.msg}
                                            </span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Your Email <span className='text-danger'>*</span></label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={email} onChange={(e) => setemail(e.target.value)} name='email' />
                                                    <span className="text-danger">
                                                        {errors.find(obj => obj.path == "email")?.msg}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Your Mobile <span className='text-danger'>*</span></label>
                                                    <div className="d-flex align-items-baseline gap-2">
                                                        <span className="">+91</span>
                                                        <input className='form-control shadow-none mb-3' type="text" minLength={10} maxLength={10} value={mobile} onChange={(e) => setmobile(e.target.value)} name='mobile' />

                                                    </div>
                                                    {/* <span className="text-danger">
                                                {errors.find(obj => obj.path == "contact.mobile")?.msg}
                                            </span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Your Password <span className='text-danger'>*</span></label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={password} onChange={(e) => setpassword(e.target.value)} name='password' />
                                                    {/* <span className="text-danger">
                                                {errors.find(obj => obj.path == "password")?.msg}
                                            </span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Select State</label>
                                                    <select onChange={(e) => setstate(e.target.value)} name="state" className='form-select mb-3 form-control shadow-none shadow-none' id="">
                                                        <option value="" selected disabled>---Select---</option>
                                                        {
                                                            states.map((state) => (
                                                                <>
                                                                    <option value={state.state}>{state.state}</option>
                                                                </>
                                                            ))
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.find(obj => obj.path == "location.state")?.msg}
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter City</label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={city} onChange={(e) => setcity(e.target.value)} name="city" id="" />
                                                </div>

                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Enter Pincode</label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={pincode} onChange={(e) => setpincode(e.target.value)} name="pincode" minLength={6} maxLength={6} id="" />
                                                </div>

                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group form-inner mb-3">
                                                    <label htmlFor="">Enter Address</label>
                                                    <textarea className='form-control shadow-none' name="address" value={address} onChange={(e) => setaddress(e.target.value)} rows="2" placeholder="Address"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button onClick={nextSlide} className="btn bg-green text-white">Save & Next</button>

                                            </div>


                                        </div>
                                    </div>

                                </>
                            )
                        }




                        {
                            slide == 2 && (
                                <>
                                    <div className="content form-wrapper animate__animated animate__backInRight">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <h3>Document Details</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Pan Number</label>
                                                    <input className='form-control shadow-none mb-3 text-uppercase' type="text" value={pannumber} maxLength={10} minLength={10} onChange={(e) => setpannumber(e.target.value)} name="number" data-obj="pancard" />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Pan Image</label>
                                                    <input className='form-control shadow-none mb-3' type="file" onChange={(e) => handlepanimage(e)} name="panfile" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Date of incorporation</label>
                                                    <input className='form-control shadow-none mb-3' type="date" value={incorpdate} onChange={(e) => setincorpdate(e.target.value)} name="date_of_incorporation" data-obj="incorporation_certificate" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Incorporation Document</label>
                                                    <input className='form-control shadow-none mb-3' type="file" onChange={(e) => handleincorporationdoc(e)} name="incorporationfile" />
                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">GST Number</label>
                                                    <input className='form-control shadow-none mb-3' type="text" maxLength={15} minLength={15} value={gstnumber} onChange={(e) => setgstnumber(e.target.value)} name="number" data-obj="gst_certificate" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">GST Document</label>
                                                    <input className='form-control shadow-none mb-3' type="file" onChange={(e) => handlegstdocument(e)} name="gst_document" data-obj="gst_document" />
                                                </div>

                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Bank Name</label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={bankname} onChange={(e) => setbankname(e.target.value)} name="bank_name" data-obj="bank" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">Account Number</label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={accountnumber} onChange={(e) => setaccountnumber(e.target.value)} name="account_no" data-obj="bank" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-inner">
                                                    <label htmlFor="">IFSC Code Number</label>
                                                    <input className='form-control shadow-none mb-3' type="text" value={ifsc} onChange={(e) => setifsc(e.target.value)} name="ifsc" data-obj="bank" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button onClick={backSlide} className=" bg-dark text-white px-5 btn">Back</button>
                                                <button className='bg-green text-white px-5 btn ms-4' onClick={submitdata}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                } />




            </div>

        </>
    );
};

export default AddSeller;









