import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Form } from 'react-bootstrap'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { BaseUrl } from '../Url';
import { toast } from 'react-toastify';
import Loader from '../Components/Loader';
import logo from "../assets/img/logo.png"
import { imgurl } from '../Api/Api';

function Blog() {
    const [desc, setdesc] = useState("")
    const [title, settitle] = useState("")
    const [file, setfile] = useState("")
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const [shortdes, setshortdes] = useState("")
    const [editid, seteditid] = useState("")



    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };

    const handleget = () => {
        axios.get(`${BaseUrl}/blog`, { headers }).then((res) => {
            console.log(res.data)
            setdata(res.data)

        })
    }

    useEffect(() => {
        handleget()
    }, [])

    const handlefile = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setfile(selectedfile)
    }

    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setloading(true)
        let formdata = new FormData()
        if (editid) {
            formdata.append("blog_id", editid)
        }
        formdata.append("title", title)
        formdata.append("description", desc)
        if (file) {
            formdata.append("image", file)
        }
        formdata.append("short_description", shortdes)
        if (!editid) {
            axios.post(`${BaseUrl}/blog`, formdata, { headers }).then((res) => {
                if (res.status == "200") {
                    toast.success("Data Submit Successfully")
                    settitle("")
                    handleget()
                    setshortdes("")
                    setdesc("")
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
        } else {
            axios.put(`${BaseUrl}/blog`, formdata, { headers }).then((res) => {
                if (res.status == "200") {
                    toast.success("Data Updated Successfully")
                    settitle("")
                    handleget()
                    setshortdes("")
                    setdesc("")
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
        }

    }

    const columns = [
        {
            field: "images",
            headerName: "Images",
            flex: 1,
            renderCell: (params) => {
                let image = params.row.image
                return <img src={`${imgurl}/${image}`} onError={(e) => {
                    e.target.onerror = null; // prevent infinite loop
                    e.target.src = logo; // replace with your error image URL
                }} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} />
            }
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "short_description",
            headerName: "Short Description",
            flex: 1,
            // renderCell: (params) => {
            //     return (
            //         <div dangerouslySetInnerHTML={{ __html: params.row.description }}></div>
            //     )
            // }
        },



        {
            field: "createdAt",
            headerName: "Joining Date",
            flex: 1,
            renderCell: (params) => {
                return params.row.createdAt?.substring(0, 10)
            }

        },

        {
            field: "Action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className='d-flex gap-2 mt-2'>
                        <button className='btn custombtn' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn custombtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </div>
                )
            }

        },
    ]


    const handleedit = (e, item) => {
        e.preventDefault()
        settitle(item.title)
        setshortdes(item.short_description)
        setdesc(item.description)
        seteditid(item._id)
    }

    const handledelete = (e, delid) => {
        e.preventDefault()
        setloading(true)
        let requestData = {
            data: {
                blog_id: delid,

            },
            headers: headers
        };
        axios.delete(`${BaseUrl}/blog`, requestData).then((res) => {
            console.log()
            if (res.data.is_success == "1") {
                toast.success(res.data.message)
                handleget()
                setloading(false)
            }
        })

    }

    const getRowId = (row) => row._id

    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <h3>Blogs</h3>
                    </div>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row gy-3">
                            <div className="col-md-4">
                                <label htmlFor="">Title</label>
                                <input type="text" value={title} onChange={(e) => settitle(e.target.value)} className='form-control shadow-none' />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Image</label>
                                <input type="file" className='form-control shadow-none' onChange={(e) => handlefile(e)} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Short Description</label>
                                <input type="text" value={shortdes} maxLength={100} className='form-control shadow-none' onChange={(e) => setshortdes(e.target.value)} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="">Blog Detail</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    onChange={handleEditorChange}
                                    className="custom-ckeditor"
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="">
                                    <button class="btn bg-green text-white ">Submit</button>
                                </div>
                            </div>
                        </div>

                    </Form>

                    <div className="col-md-12">
                        <h3>Blog List</h3>
                        <div className="tableview">
                            <Box sx={{ height: 500, width: "100%" }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    pageSize={5}
                                    getRowId={getRowId}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 10 } },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                />
                            </Box>
                        </div>
                    </div>
                </>
            } />
        </>
    )
}

export default Blog
