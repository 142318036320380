import logo from './logo.svg';
import './App.css';
import Sidebaar from './Components/Sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import User from './Pages/Category';
import 'react-toastify/dist/ReactToastify.css';
import Category from './Pages/Category';
import SubCategories from './Pages/SubCategories';
import PrivateRoutes from './Api/PrivateRoute';

import AllProduct from './Pages/AllProduct';
import "../src/assets/card.css"
import Login from './Pages/Login';
import AuctionData from './Pages/AuctionData';
import Policy from './Pages/Policy';
import Faq from './Pages/Faq';
import Alluser from './Pages/Alluser';
import ContactPage from './Pages/ContactPage';
import AllBid from './Pages/AllBid';
import Allbid from './Pages/AllBid';
import Blog from './Pages/Blog';
import ContactUs from './Pages/ContactUs';
import ProductEdit from './Pages/ProductEdit';
import Userinfo from './Pages/Userinfo';
import AddBuyer from './Pages/AddBuyer';
import AddSeller from './Pages/AddSeller';
import BatchList from './Pages/BatchList';
import BatchProduct from './Pages/BatchProduct';
import PushNotifications from './Pages/PushNotifications';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes >
          <Route element={<PrivateRoutes />} >
            <Route path='/home' element={<Home />} />
            <Route path='/category' element={<Category />} />
            <Route path='/subcategory' element={<SubCategories />} />
            <Route path='/allproduct' element={<AllProduct />} />
            <Route path='/allbid' element={<AllBid />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/batchlist' element={<BatchList />} />
            <Route path='/batchlist/product' element={<BatchProduct />} />
            <Route path="notification" element={<PushNotifications />} />


            <Route path='/alluser' element={<Alluser />} />
            <Route path='/alluser/userinfo' element={<Userinfo />} />

            <Route path='/alluser/:status/:type?' element={<Alluser />} />
            <Route path='/alluser/addbuyer' element={<AddBuyer />} />

            <Route path='/alluser/addseller' element={<AddSeller />} />



            <Route path='/contactsocial' element={<ContactPage />} />
            <Route path='/allproduct/auction/:id' element={<AuctionData />} />
            <Route path='/blog' element={<Blog />} />


            <Route path='/enquiry' element={<ContactUs />} />
            <Route path={`/allproduct/editproduct/:url`} element={<ProductEdit />} />


          </Route>
          <Route path='/' element={<Login />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
