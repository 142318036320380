import axios from "axios"
import { BaseUrl } from "../Url"
import { toast } from "react-toastify";

export const imgurl = "https://punaah.in:8080/uploads"



// const token = localStorage.getItem("token")

// const headers = {
//     Authorization: `Bearer ${token}`
// }



export const acutionlist = async (endpoint) => {
    try {
        const items = await axios.get(`${BaseUrl}/${endpoint}`);
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}

export const getdata = async (endpoint, headers) => {
    try {
        const items = await axios.get(`${BaseUrl}/${endpoint}`, { headers });
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}


export const putdata = async (endpoint, requestdata, headers) => {
    try {
        const items = await axios.put(`${BaseUrl}/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}



export const postdata = async (endpoint, id, headers) => {
    let requestdata = {
        product_id: id
    }
    try {
        const items = await axios.post(`${BaseUrl}/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}


export const post = async (endpoint, requestdata, headers) => {

    try {
        const items = await axios.post(`${BaseUrl}/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}
export const getwithoutheader = async (endpoint) => {

    try {
        const items = await axios.get(`${BaseUrl}/${endpoint}`);
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}

export const deleteApi = async (endpoint, requestdata) => {

    try {
        const items = await axios.delete(`${BaseUrl}/${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {
        // console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}


export const get_states = async () => {
    try {
        const items = await axios.get(`${BaseUrl}/admin/states`);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: [], errors: errors, is_success: 0 }
    }
}