import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Col, Form, Modal } from "react-bootstrap"
import { acutionlist, getdata, imgurl } from '../Api/Api'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import smileemoji from "../assets/img/smile-emo.svg";
import logo from "../assets/img/logo.png"
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'

import Switch from "react-switch";
import moment from 'moment'

function BatchProduct() {
    const { state } = useLocation()
    console.log("state", state)
    const [page, setpage] = useState("1")
    const [totalpage, settotalpage] = useState("")
    const [unitcost, setunitcost] = useState("")
    const navigate = useNavigate()

    const [show, setShow] = useState(false);
    const [modalid, setmodalid] = useState("")
    const [startat, setstartat] = useState("")
    const [endat, setendat] = useState("")


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setdata] = useState(state)
    let token = localStorage.getItem("token")

    const headers = {
        Authorization: `Bearer ${token}`
    }

    const [checked, setChecked] = useState(false);

    const handleChange = (id, item) => {
        console.log(item)
        setShow(true)
        // setChecked(checked);
        setunitcost(item?.unit_cost)
        setmodalid(id)
        setstartat(moment(item?.start_at).format("YYYY-MM-DDTHH:mm"))

        setendat(moment(item?.end_at).format("YYYY-MM-DDTHH:mm"))

    };

    const [loading, setloading] = useState(false)








    const handleinfo = (e, id) => {
        e.preventDefault()
        // console.log(id)


        if (id.bidCount > 0) {
            navigate(`/allproduct/auction/${id._id}`)
        } else {
            toast.error("This product does not have any bids.");
        }
    }


    const handledelete = (del) => {
        setloading(true)
        console.log(del)
        let requestData = {
            data: {
                id: del._id
            },
            headers: headers
        };
        axios.delete(`${BaseUrl}/product/destroy`, requestData)
            .then((res) => {
                // console.log("ress", res)
                if (res?.data.is_success == "1") {
                    toast.success("Deleted Successfully");
                    navigate('/batchlist')
                }
            })
            .catch((error) => {
                console.error("Error deleting category:", error);
            }).finally(() => {
                setloading(false)
            })
    };



    const handleedit = (e, item) => {
        e.preventDefault()
        if (item?.product?.is_approved == false) {
            navigate(`/allproduct/editproduct/${item?.product?.url}`)
        } else {
            toast.error("You cannot edit this product  it is active.");
        }
    }


    const handlemodalsubmit = async (e) => {
        e.preventDefault()


        setloading(true)
        let requestData = {
            product_id: modalid
        }

        let senddata = {
            product_id: modalid,
            unit_cost: unitcost,
            start_at: startat,
            end_at: endat,
        };



        await axios.put(`${BaseUrl}/product/update`, senddata, { headers })
            .then((res) => {


            })
            .catch((error) => {
                console.error("Error:", error);

            })

        await axios.post(`${BaseUrl}/admin/product_approval_change`, requestData, { headers }).then((res) => {
            if (res?.data?.data != "") {
                toast.success("Succesffully Update")

                setShow(false)
                navigate('/batchlist')

            }
        }).finally(() => {
            setloading(false)
        })





    }



    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="row mt-3 tableview align displaynonepagination">
                        <div className="col-md-12">

                            <div className="">
                                {/* <Box >
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        pageSize={data.length} // Set the page size to the total number of rows
                                        pagination={false}     // Disable pagination
                                    />
                                </Box> */}
                                <div className="table-responsive tableview customtable">
                                    <table className="table">
                                        <thead>
                                            <tr>

                                                <th>Images</th>
                                                <th>Batch Id</th>
                                                <th>Title</th>
                                                <th>Quantity</th>
                                                <th>Unit Cost</th>
                                                {/* <th>Bidding Price</th> */}

                                                <th>Change Status</th>
                                                <th>Is Active</th>
                                                <th>Winning User</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data?.map((item) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td><img src={`${imgurl}/${item?.product?.images?.[0]?.image}`} onError={(e) => {
                                                                e.target.onerror = null; // prevent infinite loop
                                                                e.target.src = logo; // replace with your error image URL
                                                            }} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} /></td>
                                                            <td>
                                                                {item?.product?.batch_id}
                                                            </td>
                                                            <td>
                                                                <ul className='list-unstyled mb-0 '>
                                                                    <li> <b>Product : </b>
                                                                        {item.product?.title}</li>

                                                                    {/* <li > <b>Category : </b>{item?.product?.category_id?.title}</li> */}

                                                                </ul>
                                                            </td>
                                                            <td>{item?.product?.quantity?.toFixed(2)} </td>
                                                            <td>
                                                                {item?.product?.unit_cost
                                                                    ? <>
                                                                        <span>&#8377;</span>{item?.product?.unit_cost} / <span>{item?.product?.unit}</span>
                                                                    </>
                                                                    : ""
                                                                }
                                                            </td>
                                                            {/* <td>&#8377;{(item?.product?.quantity * item?.product?.subcategory_id?.rate)?.toFixed(2)}</td> */}

                                                            <td>
                                                                <ul className='list-unstyled mb-0 '>
                                                                    <li><b>Product Start at</b> : {moment(item?.product?.start_at).format("YYYY-MM-DD ")}</li>
                                                                    <li><b>Product End at</b> : {moment(item?.product?.end_at).format("YYYY-MM-DD ")}</li>
                                                                    <li>
                                                                        Biding Status :
                                                                        <span className={''}>
                                                                            {
                                                                                moment().format('YYYY-MM-DD') > moment(item?.product?.end_at).format("YYYY-MM-DD ") || item?.product?.is_completed == "Completed" ? 'Time Closed' : "Active"
                                                                            }
                                                                        </span>


                                                                    </li>
                                                                    <li>
                                                                        Current Status : {item?.product?.is_completed}
                                                                    </li>


                                                                </ul>
                                                            </td>
                                                            <td><Switch onChange={(e) => handleChange(item?.product?._id, item.product)} checked={item?.product?.is_approved} /></td>
                                                            <td>{item?.product?.winner_id?.name}</td>
                                                            <td>
                                                                <button className='btn custombtn me-2' onClick={(e) => handleedit(e, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                                                <button className='btn custombtn' onClick={(e) => handledelete(item.product)}><i className="fa-solid fa-trash"></i></button></td>

                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Product Active</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handlemodalsubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="">Minimum Amount</label>
                                    <input type="text" value={unitcost} onChange={(e) => setunitcost(e.target.value)} className='form-control' />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor=""> Start Date</label>
                                    <input type="datetime-local" value={startat} onChange={(e) => setstartat(e.target.value)} className='form-control shadow-none' />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="">End Date</label>
                                    <input type="datetime-local" value={endat} onChange={(e) => setendat(e.target.value)} className='form-control shadow-none' />
                                </div>
                                <button className='btn bg-green text-white px-5'>Submit</button>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </>} />
        </>
    )
}

export default BatchProduct






