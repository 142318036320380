import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Form, FormGroup } from 'react-bootstrap'
import { deleteApi, getdata, getwithoutheader, post, putdata } from '../Api/Api'
import { toast } from 'react-toastify'
import axios from 'axios'
import { BaseUrl } from '../Url'
import Loader from '../Components/Loader'

function Faq() {
    const [question, setquestion] = useState("")
    const [answer, setanswer] = useState("")
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [active, setactive] = useState("0")
    const [editid, seteditid] = useState("")


    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }


    const fetchdata = async () => {
        const res = await getwithoutheader('faq')

        let data = res.data
        data = data?.reverse()
        setdata(data)
    }

    useEffect(() => {
        fetchdata()
    }, [])


    const handlepost = async (e) => {
        e.preventDefault()
        let requestdata = {
            question: question,
            answer: answer
        }
        let res;
        if (editid) {
            let updatedata = { ...requestdata, faq_id: editid }
            res = await putdata('faq', updatedata, headers)
        } else {
            res = await post('faq', requestdata, headers)
        }


        if (res.is_success == "1") {
            toast.success(res.message)
            fetchdata()
            setquestion("")
            setanswer("")
        } else {
            toast.error(res.message)
        }

    }
    const handledelete = async (e, id) => {
        e.preventDefault();

        setloading(true)
        const requestdata = {
            data: {
                id: id._id
            },
            headers: headers
        };
        const res = await deleteApi("faq", requestdata)
        if (res.is_success == "1") {
            toast.success(res.message);

            fetchdata();
            setloading(false)
        } else {
            toast.error(res.message);
        }
    };


    const handleedit = (e, edit) => {
        e.preventDefault()
        setquestion(edit.question)
        setanswer(edit.answer)
        seteditid(edit._id)

    }
    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={<>
                <Form onSubmit={(e) => handlepost(e)}>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className='mb-3'>Faq</h3>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <label htmlFor="">Question ?</label>
                                <textarea name="" required value={question} onChange={(e) => setquestion(e.target.value)} className='form-control shadow-none' id=""></textarea>
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <label htmlFor="">Answer</label>
                                <textarea name="" required value={answer} onChange={(e) => setanswer(e.target.value)} className='form-control shadow-none' id=""></textarea>
                            </FormGroup>
                        </div>
                        <div className="col-md-12">
                            <button className='btn mt-3 bg-green text-white'>Submit</button>
                        </div>
                    </div>
                </Form>
                <div className="col-md-12 ">
                    <h3 className='mt-5'>Faq List</h3>
                    <div className="accordion my-4" id="accordionExample">
                        {data.map((item, index) => {
                            return (
                                <>
                                    <div className="accordion-item tableview">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button ${active != index && "collapsed"}`} onClick={(e) => setactive(index)} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={active == index ? true : false} aria-controls={`collapse${index}`}>
                                                <div className="d-flex justify-content-between w-100">
                                                    <p>{item.question}</p>
                                                    <div className="me-3">
                                                        <button className='btn custombtn me-2' onClick={(e) => handleedit(e, item)} ><i className="fa-solid fa-pen-to-square"></i></button>
                                                        <button className='btn custombtn' onClick={(e) => handledelete(e, item)} ><i class="fa-solid fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id={`collapse${index}`} className={`accordion-collapse collapse ${active == index ? "show" : ""}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body" style={{ background: "#dddddd6b" }}>

                                                <p className='w-100 mb-0'>{item.answer}</p>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </>} />
        </>
    )
}

export default Faq



