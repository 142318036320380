
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import Loader from '../Components/Loader'
import { get_states } from '../Api/Api'
import { BaseUrl } from '../Url'
import Sidebaar from '../Components/Sidebar'
import TitleComp from '../Components/TitleComp'

function AddBuyer() {
    const [name, setname] = useState("")
    const [businessname, setbusinessname] = useState("")
    const [statesdata, setStatesdata] = useState([])
    const [phone, setphone] = useState("")
    const [state, setstate] = useState("")
    const [loading, setloading] = useState(false)
    const [bankaccnumber, setbankaccnumber] = useState("")


    const [address, setaddress] = useState("")
    const [email, setemail] = useState("")
    const [city, setcity] = useState("")
    const [pincode, setpincode] = useState("")
    const [pannumber, setpannumber] = useState("")
    const [gstnumber, setgstnumber] = useState("")
    const [dateofinc, setdateofinc] = useState("")
    const [bankacname, setbankacname] = useState("")
    const [ifsc, setifsc] = useState("")



    const [panimage, setpanimage] = useState("")
    const [profileimage, setprofileimage] = useState("")
    const [doi, setdoi] = useState("")
    const [gst_image, setgst_image] = useState("")



    const navigate = useNavigate()




    const handlepanimage = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setpanimage(selectedfile)
    }


    const handleincorporationdoc = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setdoi(selectedfile)
    }



    const handlegstdocument = (e) => {
        e.preventDefault()
        let selectedfile = e.target.files[0]
        setgst_image(selectedfile)
    }


    const getstates = async () => {
        const resp = await get_states();
        setStatesdata(resp.data)
    }
    useEffect(() => {
        getstates();
    }, []);







    const handleFormSubmit = (e) => {
        e.preventDefault();
        setloading(true)

        let formData = new FormData();



        let formDataObject = {
            name: name,
            email: email,
            business_name: businessname,

            contact: {
                country_code: "+91",
                mobile: phone,
                whatsapp: phone
            },
            address: {
                address: address,
                city: city,
                state: state,
                pincode: pincode
            },
            documents: {
                pancard: {
                    number: pannumber,

                },
                bank: {
                    bank_name: bankacname,
                    branch_name: "",
                    account_no: bankaccnumber,
                    ifsc: ifsc,

                },
                incorporation_certificate: {
                    date_of_incorporation: dateofinc,

                },
                gst_certificate: {
                    number: gstnumber,

                }
            },
            login_type: {
                role: "User",
                isBuyer: true,
                isSeller: false
            },
            password: "Sd@348jfjd@dfkfdj",
            core_password: "Sd@348jfjd@dfkfdj"
        };






        axios.post(`${BaseUrl}/user`, formDataObject)
            .then((res) => {
                console.log(res.data.is_success);
                if (res.data.errors) {
                    res.data.errors.map((item) => {
                        toast.error(item.msg)
                    })
                }
                if (res.data.is_success === 1) {
                    // toast.success("Data Submitted Successfully");

                    updateimage(res.data.data)

                } else {
                    // Handle case when is_success is not 1 (optional)
                    console.error("Data submission failed:", res.data);
                    toast.error("Data Submission Failed");
                }
            })
            .catch((error) => {
                // Handle errors
                console.error("Error occurred during data submission:", error);
                toast.error("An error occurred during data submission");
            }).finally(() => {
                setloading(false)
            })

    }


    const updateimage = async (imageToken) => {
        let formData = new FormData();
        setloading(true)
        formData.append("panimage", panimage);
        formData.append("profile_image", profileimage);
        formData.append("doi", doi);
        formData.append("gst_image", gst_image);

        axios.post(`${BaseUrl}/user/upload-images`, formData, {
            headers: {
                Authorization: `Bearer ${imageToken}`
            }
        }).then((res) => {
            if (res.data.is_success == 1) {
                toast.success("Buyer Create Succesfully")
                navigate("/alluser")



            }
        }).catch((error) => {
            console.error("Error:", error);
            // Handle error, show error message or take appropriate action
        }).finally(() => {
            setloading(false)
        })
    }

    return (
        <>
            {loading && <Loader />}

            <Sidebaar content={
                <>
                    <TitleComp title="Add Buyer" />
                    <Form>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Name</label>
                                    <input className='form-control shadow-none my-2' type="text" value={name} onChange={(e) => setname(e.target.value)} placeholder="Your Name :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Business Name</label>
                                    <input className='form-control shadow-none my-2' type="text" value={businessname} onChange={(e) => setbusinessname(e.target.value)} placeholder="Business Name :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Email</label>
                                    <input className='form-control shadow-none my-2' type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Your Email :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Phone</label>
                                    <input className='form-control shadow-none my-2' type="text" value={phone} onChange={(e) => setphone(e.target.value)} placeholder="Your Phone :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Address</label>
                                    <input className='form-control shadow-none my-2' type="text" value={address} onChange={(e) => setaddress(e.target.value)} placeholder="Address :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">State</label>
                                    <select name="" className="form-control shadow-none my-2 form-select" value={state} onChange={(e) => setstate(e.target.value)} id="" style={{ color: "grey" }}>
                                        <option value="" >Select State</option>
                                        {statesdata.map((item) => {
                                            return (
                                                <>
                                                    <option value={item.state}>{item.state}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">City</label>
                                    <input className='form-control shadow-none my-2' type="text" value={city} onChange={(e) => setcity(e.target.value)} placeholder="City :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Pincode</label>

                                    <input className='form-control shadow-none my-2' type="text" value={pincode} onChange={(e) => setpincode(e.target.value)} placeholder="Pincode :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Pan </label>

                                    <input className='form-control shadow-none my-2' type="text" value={pannumber} onChange={(e) => setpannumber(e.target.value)} placeholder="Pan Number :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Gst</label>

                                    <input className='form-control shadow-none my-2' type="text" value={gstnumber} onChange={(e) => setgstnumber(e.target.value)} placeholder="Gst Number :" />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Pan Image</label>
                                    <input className='form-control shadow-none my-2' type="file" onChange={(e) => handlepanimage(e)} placeholder="Pan Image :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Gst Certificate</label>
                                    <input className='form-control shadow-none my-2' type="file" onChange={(e) => handlegstdocument(e)} placeholder="Gst Certificate :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Date of Incorporation</label>
                                    <input className='form-control shadow-none my-2' type="date" value={dateofinc} onChange={(e) => setdateofinc(e.target.value)} placeholder="Date of Incorporation :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Incorporation Certificate</label>
                                    <input className='form-control shadow-none my-2' type="file" onChange={(e) => handleincorporationdoc(e)} placeholder="Date of Incorporation :" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Bank Ac Name</label>
                                    <input className='form-control shadow-none my-2' type="text" value={bankacname} onChange={(e) => setbankacname(e.target.value)} placeholder="Bank Account Name" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">Bank Ac Number</label>
                                    <input className='form-control shadow-none my-2' type="number" value={bankaccnumber} onChange={(e) => setbankaccnumber(e.target.value)} placeholder="Bank Account Number" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-inner">
                                    <label htmlFor="">IFSC Code</label>
                                    <input className='form-control shadow-none my-2' type="text" value={ifsc} onChange={(e) => setifsc(e.target.value)} placeholder="IFSC Code" />
                                </div>
                            </div>

                            <div className="col-12">
                                <button type="submit" onClick={(e) => handleFormSubmit(e)} className="btn bg-green px-5 mt-3 text-white">
                                    Submit
                                </button>
                            </div>


                        </div>



                    </Form>
                </>
            } />

        </>
    )
}

export default AddBuyer
