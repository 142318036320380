import React, { useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { useLocation } from 'react-router-dom'
import { BaseUrl, imgurl } from '../Url'
import logo from "../assets/img/logo.png"
import approvedimg from "../assets/img/approved.png"
import rejected from "../assets/img/rejected.png"
import notfound from "../assets/img/notfound.png"
import document from "../assets/img/document.jpg"
import { Modal } from 'react-bootstrap'



function Userinfo() {

    const { state } = useLocation()


    const [show, setShow] = useState(false);
    const [modalimg, setmodalimg] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    // address
    // :
    // address
    // :
    // "Vikaspuri "
    // city
    // :
    // "Delhi"
    // pincode
    // :
    // "110018"
    // state?
    // :
    // "Delhi"


    return (
        <Sidebaar content={
            <>
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-11" >
                        <div className="row bg-white shadow rounded-4">
                            <div className="col-md-2">
                                <div className="m-2 rounded-5 bg-white shadow p-1" style={{ width: "max-content" }}>
                                    <div className="bg-light p-3 rounded-5">
                                        <img
                                            src={`${BaseUrl}/${state?.profile_image}`}
                                            onError={(e) => {
                                                e.target.onerror = null; // prevent infinite loop in case the error image also fails to load
                                                e.target.src = `${logo}`; // replace with your error image URL
                                            }}
                                            className=""
                                            style={{ height: "100px" }}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="mt-3">

                                    <div className="ps-3 mt-2">
                                        <h3 className="h3 capitalize">{state?.name} <span className='bg-primary text-white px-3 py-1 rounded-3 position-relative' style={{ fontSize: "14px", bottom: "6px" }}>{state?.login_type.isBuyer ? "Buyer" : "Seller"}</span></h3>
                                    </div>
                                    {state?.address && (
                                        <h6 className="text-base bg-info  rounded-2 text-white p-1 mt-2  px-3 capitalize" style={{ width: "max-content" }}>
                                            {state?.address.address}, {state?.address.city} {state?.address.state}, {state?.address.pincode}
                                        </h6>
                                    )}
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="ps-3  my-2 d-flex align-items-center gap-2">
                                            <h3 className="h5 capitalize mb-0">Bussiness Name:</h3>
                                            <p className='mb-0'>{state?.business_name}</p>
                                        </div>
                                        <div className="ps-3 mt-2 d-flex align-items-center gap-2">
                                            <h3 className="h5 capitalize mb-0">Verified:</h3>
                                            {state?.is_verified ? (
                                                <img src={approvedimg} className="img-fluid" width={50} alt="Approved" />
                                            ) : (
                                                <img src={rejected} className="img-fluid" width={50} alt="Rejected" />
                                            )}
                                        </div>
                                        <div className="ps-3  my-2 d-flex align-items-center gap-2">
                                            <h3 className="h5 capitalize mb-0">Email:</h3>
                                            <p className='mb-0'>{state?.email}</p>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="ps-3 mb-3 mt-2 d-flex align-items-center gap-2">
                                            <h3 className="h5 capitalize mb-0">Mobile:</h3>
                                            <p className='mb-0'>{state?.contact.mobile}</p>
                                        </div>
                                        <div className="ps-3  my-2 d-flex align-items-center gap-2">
                                            <h3 className="h5 capitalize mb-0">Whatsapp:</h3>
                                            <p className='mb-0'>{state?.contact.whatsapp}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <p className='text-danger mb-0'> <i class="fa-solid fa-circle-info"></i> If you click on the image, it will enlarge for a clearer view.</p>

                </div>
                <div className="col-md-4">
                    <div className="shadow bg-white rounded boxdetail">
                        <div className="mainboxdetail  p-3 rounded-top">
                            <h2 className="h3 mb-0 text-white">
                                Bank
                            </h2>
                        </div>
                        <div className="p-3">
                            <div className="mb-4 row">
                                <div className="col-md-6 font-weight-bold">
                                    <h4>Account Number</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>{state?.documents.bank.account_no}</p>
                                </div>
                            </div>
                            <div className="mb-4 row">
                                <div className="col-md-6 font-weight-bold">
                                    <h4>Bank Name</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>{state?.documents.bank.bank_name}</p>
                                </div>
                            </div>
                            <div className="mb-4 row">
                                <div className="col-md-6 font-weight-bold">
                                    <h4>IFSC</h4>
                                </div>
                                <div className="col-md-6">
                                    <p>{state?.documents.bank.ifsc}</p>
                                </div>
                            </div>









                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="shadow bg-white rounded boxdetail">
                        <div className="mainboxdetail  p-3 rounded-top">
                            <h2 className="h3 mb-0 text-white">
                                GST Certificate
                            </h2>
                        </div>
                        <div className="p-3">
                            <div className="text-center">
                                {state?.documents?.gst_certificate?.image?.endsWith('.pdf') ? (
                                    <a href={`${imgurl}${state?.documents.gst_certificate.image}`} target="_blank" rel="noopener noreferrer">
                                        <img src={document} style={{ height: "130px" }} alt="" />
                                    </a>
                                ) : (
                                    <img
                                        src={`${imgurl}${state?.documents.gst_certificate.image}`}
                                        onError={(e) => {
                                            e.target.onerror = null; // prevent infinite loop in case the error image also fails to load
                                            e.target.src = `${notfound}`; // replace with your error image URL
                                        }}
                                        onClick={(e) => { setShow(true); setmodalimg(`${imgurl}${state?.documents.gst_certificate?.image}`) }}
                                        alt=""
                                        style={{ height: "150px" }}
                                    />
                                )}

                                <p>{state?.documents.gst_certificate.number}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="shadow bg-white rounded boxdetail">
                        <div className="mainboxdetail p-3 rounded-top">
                            <h2 className="h3 mb-0 text-white">Date of Incorporation</h2>
                        </div>
                        <div className="p-3">
                            <div className="text-center">
                                {state?.documents?.incorporation_certificate?.image?.endsWith('.pdf') ? (
                                    <a href={`${imgurl}${state?.documents.incorporation_certificate.image}`} target="_blank" rel="noopener noreferrer">
                                        <img src={document} style={{ height: "130px" }} alt="Open PDF" />
                                    </a>
                                ) : (
                                    <img
                                        src={`${imgurl}${state?.documents.incorporation_certificate.image}`}
                                        onError={(e) => {
                                            e.target.onerror = null; // prevent infinite loop in case the error image also fails to load
                                            e.target.src = `${notfound}`; // replace with your error image URL
                                        }}
                                        onClick={(e) => { setShow(true); setmodalimg(`${imgurl}${state?.documents.incorporation_certificate.image}`) }}
                                        alt=""
                                        style={{ height: "150px" }}
                                    />
                                )}
                                <p>{state?.documents?.incorporation_certificate.date_of_incorporation?.substring(0, 10)}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="shadow bg-white rounded boxdetail">
                        <div className="mainboxdetail p-3 rounded-top">
                            <h2 className="h3 mb-0 text-white">Pan Card</h2>
                        </div>
                        <div className="p-3">
                            <div className="text-center">
                                {state?.documents?.pancard?.image?.endsWith('.pdf') ? (
                                    <a href={`${imgurl}${state?.documents.pancard.image}`} target="_blank" rel="noopener noreferrer">
                                        <img src={document} style={{ height: "130px" }} alt="Open PDF" />
                                    </a>
                                ) : (
                                    <img
                                        src={`${imgurl}${state?.documents.pancard.image}`}
                                        onError={(e) => {
                                            e.target.onerror = null; // prevent infinite loop in case the error image also fails to load
                                            e.target.src = `${notfound}`; // replace with your error image URL
                                        }}
                                        onClick={(e) => { setShow(true); setmodalimg(`${imgurl}${state?.documents?.pancard.image}`) }}

                                        alt=""
                                        style={{ height: "150px" }}
                                    />
                                )}
                                <p>{state?.documents.pancard.number}</p>
                            </div>
                        </div>
                    </div>
                </div>



                <Modal show={show} size='lg' onHide={handleClose}>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src={modalimg} style={{ height: "500px", objectFit: "contain" }} className='img-fluid' alt="" />
                        </div>
                    </Modal.Body>

                </Modal>

            </>
        } />
    )
}

export default Userinfo




