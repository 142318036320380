import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Sidebaar from '../Components/Sidebar'
import { getdata, imgurl } from '../Api/Api';
import axios from 'axios';
import { BaseUrl } from '../Url';
import { useNavigate } from 'react-router-dom';

function Home() {
    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [logsdata, setlogsdata] = useState([])



    const [seriesData, setSeriesData] = useState([]);
    const chartComponentRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${BaseUrl}/subcategory/logs`); // Replace with your API endpoint
            const data = await response.json();
            return data;
        };

        const processData = (data) => {
            return data.map(item => ({
                name: item.name,
                data: item.data.map(point => [point[0], point[1]])
            }));
        };

        const initializeChart = async () => {
            const apiData = await fetchData();
            const processedData = processData(apiData);
            setSeriesData(processedData);
        };

        initializeChart();
    }, []);

    const options = {
        chart: {
            type: 'spline'
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                month: '%e. %b',
                year: '%b'
            },
            title: {
                text: 'Date'
            }
        },
        yAxis: {
            title: {
                text: 'Sub Categories (₹)'
            },
            min: 0
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%e. %b}: {point.y:.2f} m'
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: 'circle',
                    fillColor: '#FFFFFF',
                    enabled: true,
                    radius: 2.5,
                    lineWidth: 1,
                    lineColor: null
                }
            }
        },
        colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
        series: seriesData
    };

    const headers = {
        Authorization: `Bearer ${token}`
    }
    const handledashbord = async () => {
        const res = await getdata(`admin/dashboard`, headers)

        setdata(res)
    }



    useEffect(() => {
        handledashbord()
        pricelogs()
    }, [])

    const pricelogs = () => {
        axios.get(`${BaseUrl}/subcategory/price-logs`, { headers }).then((res) => {
            setlogsdata(res.data.data)
            console.log(res.data.data)
        })
    }


    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    const boxes = [
        {
            title: "Total User", link: '/alluser', type: "total_user", icon: <img src="https://cdn-icons-png.freepik.com/256/921/921347.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' />
            // < i class= "fa-solid fa-users icongreen" ></i>
        },
        {
            title: "Today User", link: `/alluser/today/${formattedDate}`, type: "today_user", icon: <img src="https://cdn-icons-png.freepik.com/256/5323/5323478.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' />
            //  <i class="fa-solid fa-users icongreen"></i>
        },
        {
            title: "Unverfied User", link: `/alluser/unverified`, type: "unverified_user", icon: <img src="https://cdn-icons-png.freepik.com/256/9930/9930983.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' />
            //  <i class="fa-solid fa-users icongreen"></i>
        },
        {
            title: "Total Products", link: '/allproduct', type: "total_products", icon: <img src="https://cdn-icons-png.freepik.com/256/2898/2898492.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' />
            //  <i class="fa-solid fa-users icongreen"></i>
        },
        { title: "Total Seller", type: "total_seller", link: `/alluser/seller`, icon: <img src=" https://cdn-icons-png.freepik.com/256/9165/9165713.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' /> },
        { title: "Total Buyer", type: "total_buyer", link: `/alluser/buyer`, icon: <img src=" https://cdn-icons-png.freepik.com/256/7969/7969410.png?uid=R77132017&ga=GA1.1.1305344259.1713854685&semt=ais_hybrid" className='img-fluid' /> },
    ]
    return (
        <>
            <Sidebaar content={<>
                {/* <CKEditor
                    editor={ClassicEditor}
                    data={desc}
                    onChange={handleEditorChange}
                    className="custom-ckeditor"
                /> */}

                {boxes.map((item, index) => {
                    return (
                        <>
                            <div className="col-md-3" onClick={(e) => navigate(item.link)}>
                                <div className="dashbox">
                                    <div className="row align-items-center">
                                        <div className="col-md-9">
                                            <div className="">
                                                <h3 className=''>{data[item.type]}</h3>
                                                <p>{item.title} </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="iconbox">
                                                {item.icon}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
                <div className="col-md-12">

                    <ul class="nav nav-pills mb-3 tableview " id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active custombtn me-3" style={{ width: "200px" }} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Graph</button>
                        </li>
                        {/* <li class="nav-item" role="presentation">
                            <button class="nav-link custombtn" style={{ width: "200px" }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Live Prices</button>
                        </li> */}

                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row gy-3 mb-3">
                                {logsdata.map((item) => {
                                    let percentageChange =
                                        ((item.subcategory_id.rate - item.price) / item.price) * 100;
                                    return (
                                        <>
                                            <div className="col-md-4">
                                                <div className="bg-white shadow rounded-3 p-3">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-3">
                                                            <div className="">
                                                                <img src={`${imgurl}/${item.subcategory_id.category_id.icon}`} className='img-fluid' alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="position-relative">
                                                                <span style={{ right: 0 }} className={` text-white badge position-absolute   ${percentageChange > 0 ? " bg-green " : percentageChange == 0 ? "bg-primary" : "bg-danger "}`}> {percentageChange > 0 ? <i class="fa-solid fa-arrow-up"></i> : percentageChange == 0 ? "" : <i class="fa-solid fa-arrow-down"></i>} {percentageChange.toFixed(2)} %</span>
                                                                <h5>{item.subcategory_id.category_id.title}</h5>
                                                                <h6 className=''>{item.subcategory_id.title}</h6>
                                                                <p className='text-success fw-bold'>₹ {item.price} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>

                    </div>

                </div>

            </>} />
        </>
    )
}

export default Home











