import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Col, Form } from "react-bootstrap"
import { acutionlist, getdata, imgurl } from '../Api/Api'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import smileemoji from "../assets/img/smile-emo.svg";
import logo from "../assets/img/logo.png"
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'
import nodatafound from "../assets/img/nodatafound.png"

import Switch from "react-switch";
import TitleComp from '../Components/TitleComp'

function Alluser() {
    const { type, status } = useParams()

    console.log(type)

    const location = useLocation()
    const [search, setsearch] = useState("")
    const [maindata, setmaindata] = useState([])



    const handlall = async () => {
        setloading(true);
        const res = await getdata(`user`, headers);
        if (res.is_success == "1") {
            setloading(false);
            let users = res.data;
            if (status == "today") {
                users = users.filter(user => user.createdAt.startsWith(type));
            }
            if (status == "unverified") {
                users = users.filter(user =>
                    !user.is_verified
                );
            }
            if (status == "buyer") {
                users = users.filter(user =>
                    user.login_type.isBuyer
                );
            }
            if (status == "seller") {
                users = users.filter(user =>
                    user.login_type.isSeller
                );
            }
            setdata(users);
            setmaindata(users)
        }
    };


    useEffect(() => {
        handlall();
    }, [status]);


    const [page, setpage] = useState("1")
    const [totalpage, settotalpage] = useState("")
    const navigate = useNavigate()

    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")

    const headers = {
        Authorization: `Bearer ${token}`
    }

    // const handlall = async (pageSize) => {
    //     setloading(true)
    //     const res = await getdata(`user`, headers)
    //     if (res.is_success == "1") {
    //         setloading(false)
    //         setdata(res.data)


    //     }



    // }

    useEffect(() => {
        handlall()
    }, [])

    const [checked, setChecked] = useState(false);

    const handleChange = (checked, id) => {

        setChecked(checked);
        setloading(true)
        let requestData = {
            user_id: id
        }
        axios.post(`${BaseUrl}/user/verification-change`, requestData, { headers }).then((res) => {
            if (res.data.is_success == "1") {
                toast.success("Succesffully Update")
                handlall()
            }
        }).finally(() => {
            setloading(false)

        })
    };

    const [loading, setloading] = useState(false)

    useEffect(() => {
        // handlall(page)
    }, [])

    const getRowId = (row) => row._id

    const columns = [
        {
            field: "images",
            headerName: "Images",
            flex: 1,
            renderCell: (params) => {
                let image = params.row.images?.[0]?.image
                return <img src={`${imgurl}/${image}`} onError={(e) => {
                    e.target.onerror = null; // prevent infinite loop
                    e.target.src = logo; // replace with your error image URL
                }} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} />
            }
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            renderCell: (params) => {
                return params.row.contact.mobile
            }
        },

        {
            field: "email",
            headerName: "Email",
            flex: 1,

        },
        {
            field: "core_password",
            headerName: "Password",
            flex: 1,
        },


        {
            field: "type",
            headerName: "Type",
            flex: 1,
            renderCell: (params) => {
                return params.row.login_type.isBuyer ? "Buyer" : "Seller"
            }

        },

        {
            field: "info",
            headerName: "Info",
            flex: 1,
            renderCell: (params) => {
                // Calculate the bidding price by multiplying quantity with subcategory rate
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => navigate('/alluser/userinfo', { state: params.row })}><i class="fa-solid fa-circle-info"></i></button>
                    </>
                );
            },
        },



        {
            field: "toggle",
            headerName: "Change Status",
            flex: 1,
            renderCell: (params) => {
                // Calculate the bidding price by multiplying quantity with subcategory rate
                return (
                    <>
                        <label>
                            <Switch onChange={(e) => handleChange(checked, params.row._id)} checked={params.row.is_verified} />
                        </label>
                    </>
                );
            },
        },

        {
            field: "createdAt",
            headerName: "Joining Date",
            flex: 1,
            renderCell: (params) => {
                return params.row.createdAt?.substring(0, 10)
            }

        },

        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                // Calculate the bidding price by multiplying quantity with subcategory rate
                return (
                    <>
                        {params.row.email != "playstore@gmail.com" && <button className='btn custombtn' onClick={(e) => handledelete(params.row)}><i className="fa-solid fa-trash"></i></button>}
                    </>
                );
            },
        },





    ]


    const handledelete = (del) => {
        setloading(true)

        axios.delete(`${BaseUrl}/user/delete/${del._id}`, { headers })
            .then((res) => {
                // console.log("ress", res)
                if (res.data.is_success == "1") {
                    toast.success("Deleted Successfully");
                    handlall(page)
                }
            })
            .catch((error) => {
                console.error("Error deleting category:", error);
            }).finally(() => {
                setloading(false)
            })
    };


    const handleinfo = (e, id) => {
        e.preventDefault()
        // console.log(id)
        navigate(`/allproduct/auction/${id._id}`)
    }





    const handlesearch = () => {
        let filtered = maindata.filter((item) => {
            return item.is_verified.toString().toLowerCase() === search.toLowerCase();
        });
        setdata(filtered);
    };


    useEffect(() => {

        handlesearch()
    }, [search])











    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="row mt-3 tableview align-items-center gy-3 displaynonepagination">
                        <div className="col-md-4 ">
                            <label htmlFor="">
                                Search By Status
                            </label>
                            <select name="" value={search} onChange={(e) => setsearch(e.target.value)} className='form-control shadow-none form-select' id="">
                                <option value="">Select Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex justify-content-end align-items-center gap-3 mb-3">
                                <Link to={'/alluser/addbuyer'} className='btn bg-dark text-white'  ><i class="fa-solid fa-cart-shopping"></i> Add Buyer</Link >
                                <Link to={'/alluser/addseller'} className='btn bg-dark text-white'><i class="fa-solid fa-store"></i> Add Seller</Link >
                            </div>
                        </div>
                        <div className="col-md-12">

                            {data.length > 0 ? <div className="">
                                <Box>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        pageSize={data.length} // Set the page size to the total number of rows
                                        pagination={false}     // Disable pagination
                                    />
                                </Box>
                            </div> :
                                <div className='d-flex justify-content-center mt-5 align-items-center'>
                                    <div className="">
                                        <img src={nodatafound} className='img-fluid mb-3' style={{ height: "300px", borderRadius: "30px" }}></img>
                                        <TitleComp title="User Not Found" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                </>} />
        </>
    )
}

export default Alluser






