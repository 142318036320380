import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Col, Form } from 'react-bootstrap'
import { getdata, imgurl, postdata } from '../Api/Api'
import axios from 'axios'
import { BaseUrl } from '../Url'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'

function ProductEdit() {

    const [categorydata, setcategorydata] = useState([])

    const { url } = useParams()

    const navigate = useNavigate()
    const [data, setdata] = useState([])
    const [subcatdata, setsubcatdata] = useState([])
    const [title, settitle] = useState("")
    const [start_at, setstart_at] = useState("")
    const [end_at, setend_at] = useState("")
    const [detail, setdetail] = useState("")
    const [file, setfile] = useState([])
    const [imgpreview, setimgpreview] = useState([])
    const [loading, setloading] = useState(false)

    const [editid, seteditid] = useState("")




    const [cat, setcat] = useState("")
    const [subcat, setsubcat] = useState("")
    const handlecategory = async () => {
        const res = await getdata('category')
        setcategorydata(res.data)
    }

    const handleproductfetch = () => {
        axios.get(`${BaseUrl}/product/view/${url}`, { headers }).then((res) => {
            console.log("productdata", res.data.data)
            const productData = res.data.data;
            setdata(res.data.data)
            seteditid(productData?._id)
            setcat(productData?.category_id)
            setsubcat(productData?.subcategory_id?._id)
            setimgpreview(productData.images)
            settitle(productData?.title)
            setstart_at(moment(productData?.start_at).format("YYYY-MM-DDTHH:mm"))

            setend_at(moment(productData?.end_at).format("YYYY-MM-DDTHH:mm"))
            setdetail(productData?.description)
        })
    }




    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }


    const handlesub = async () => {
        let requestdata = {
            category_id: cat
        }
        axios.post(`${BaseUrl}/subcategory/get`, requestdata, { headers }).then((res) => {
            setsubcatdata(res.data.data.sub_category)
        })

    }

    useEffect(() => {
        handlecategory()
        handleproductfetch()
    }, [])

    useEffect(() => {
        if (categorydata.length > 0) {
            handlesub()
        }
    }, [cat])




    const handleAllImagesRemove = (e) => {
        e.preventDefault();
        setfile([])

        document.getElementById("image-input").value = "";
    };


    const handlefile = (e) => {
        const selectedFiles = Array.from(e.target.files);
        // Update the state with the selected files
        setfile(selectedFiles);

        // You can also log each selected file if needed
        selectedFiles.forEach(file => {
            console.log('Selected file:', file);
        });
    };


    const handleSubmit = async (e) => {
        setloading(true)
        e.preventDefault();
        let requestData = {
            product_id: editid,
            category_id: cat,
            subcategory_id: subcat,

            title: title,

            start_at: start_at,
            end_at: end_at,
            description: detail,

        };

        axios.put(`${BaseUrl}/product/update`, requestData, { headers })
            .then((res) => {

                if (res.data.data != "") {


                    toast.success("Data Submit Successfully")
                    navigate("/allproduct")

                }
            })
            .catch((error) => {
                console.error("Error:", error);

            })
    };




    const handleimage = (id) => {
        setloading(true)
        const formData = new FormData();


        formData.append("product_id", id);


        file.forEach((file, index) => {
            formData.append(`image`, file);
        });

        axios.post(`${BaseUrl}/product/update-images`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log("resimage", res);
            if (res.data.is_success == "1") {
                toast.success("Data Submit Successfully")
                navigate("/allproduct")

            }
        }).catch((err) => {
            toast.error(err)
        }).finally(() => {
            setloading(false)
        })
    };

    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>

                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row gy-3 align-items-center">
                            <div className="col-md-12">

                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-8">
                                        <div className="bg-white shadow p-3 rounded-3">
                                            <div className="row align-items-center">
                                                <div className="col-md-2">
                                                    <div className="">
                                                        <img className='img-fluid ' style={{ width: "100px", height: "100px", borderRadius: "50%", objectFit: "cover", boxShadow: "0 0 0 4px #ddd" }} src={`${imgurl}/${data?.user_id?.profile_image}`} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <h3>User Detail</h3>
                                                    <h5><span>Name</span> :  <span className='text-success text-capitalize '>{data?.user_id?.name}</span></h5>
                                                    <h5><span>Business Name</span> : <span className='text-success text-capitalize '>{data?.user_id?.business_name}</span></h5>
                                                    <h5><span>Address</span> :  <span className='text-success text-capitalize '>{data?.location?.city}, {data?.location?.state} ,{data?.location?.pincode}</span></h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-12">
                                <h3>Edit Product</h3>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Category</label>
                                <select name="" value={cat} onChange={(e) => setcat(e.target.value)} className='form-control shadow-none form-select' id="">
                                    <option value="">--Select--</option>
                                    {categorydata.map((item) => {
                                        return (
                                            <>
                                                <option value={item._id}>{item.title}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div>
                            {/* <div className="col-md-4">
                                <label htmlFor="">Sub Category</label>
                                <select name="" value={subcat} onChange={(e) => setsubcat(e.target.value)} className='form-control shadow-none form-select' id="">
                                    <option value="" >--Select--</option>
                                    {subcatdata?.map((item) => {
                                        return (
                                            <>
                                                <option value={item._id}>{item.title}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div> */}
                            <div className="col-md-4">
                                <label htmlFor="">Title</label>
                                <input type="text" className='form-control' value={title} onChange={(e) => settitle(e.target.value)} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Start At</label>

                                <input type="datetime-local" className='form-control' value={start_at} onChange={(e) => setstart_at(e.target.value)} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">End At</label>

                                <input type="datetime-local" className='form-control' value={end_at} onChange={(e) => setend_at(e.target.value)} />
                            </div>
                            {/* <div className="col-md-4">
                                <label htmlFor="">Images</label>

                                <input type="file" multiple className='form-control' onChange={(e) => handlefile(e)} />
                            </div> */}
                            <div className="col-md-12">
                                <label htmlFor="">Description</label>

                                <textarea name="" value={detail} onChange={(e) => setdetail(e.target.value)} className='form-control' id=""></textarea>
                            </div>
                            <div className="col-md-12">
                                <button class="btn bg-green text-white px-5">Submit</button>
                            </div>
                            <div className="col-md-12">
                                {file.length > 0 ? <Col md="12">
                                    <div className="">
                                        {file.length > 0 && (
                                            <button
                                                onClick={handleAllImagesRemove}
                                                className="remove-all-button btn mb-2 text-white bg-warning"
                                                style={{ fontSize: "12px" }}
                                            >
                                                Remove All Images
                                            </button>
                                        )}
                                        <div className="d-flex align-items-center gap-2">
                                            {file.map((image, index) => (
                                                <div key={index} className="image-preview">
                                                    <img
                                                        id='image-input'
                                                        src={URL.createObjectURL(image)}
                                                        className="img-fluid"
                                                        style={{ height: "200px", borderRadius: "10px" }}
                                                        alt={`Image ${index + 1}`}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col> : <Col md="12">
                                    <div className="">

                                        <div className="d-flex align-items-center gap-2">
                                            {imgpreview.map((image, index) => (
                                                <div key={index} className="image-preview">
                                                    <img
                                                        id='image-input'
                                                        src={`${imgurl}/${image.image}`}
                                                        className="img-fluid"
                                                        style={{ height: "200px", width: "200px", objectFit: "contain", borderRadius: "10px" }}
                                                        alt={`Image ${index + 1}`}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>}
                            </div>
                        </div>
                    </Form>
                </>
            } />
        </>
    )
}

export default ProductEdit
