import React from 'react'
import { ColorRing } from 'react-loader-spinner'

function Loader() {
    return (
        <>
            <div className="overlay">
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperclassName="color-ring-wrapper"
                    colors={['#32c36c', '#32c36c', '#32c36c', '#32c36c', '#32c36c']}
                />
            </div>
        </>
    )
}

export default Loader
