import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { getdata } from '../Api/Api'
import { BaseUrl } from '../Url'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from '../Components/Loader'


function ContactUs() {
    const [loading, setloading] = useState(false)
    const getRowId = (row) => row._id
    let token = localStorage.getItem("token")
    const [data, setdata] = useState([])


    const headers = {
        Authorization: `Bearer ${token}`
    }

    const handelget = async () => {
        const res = await getdata(`admin/contact-query`, { headers })
        console.log(res.data)
        setdata(res.data?.reverse())
    }

    useEffect(() => {
        handelget()
    }, [])

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,

        },
        {
            field: "mobile",
            headerName: "Mobile",
            flex: 1,

        },
        {
            field: "email",
            headerName: "email",
            flex: 2,

        },
        {
            field: "message",
            headerName: "Message",
            flex: 3,

        },

        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => handledelete(params.row)}><i className="fa-solid fa-trash"></i></button>
                    </>
                )
            }

        }
    ]


    const handledelete = (del) => {
        setloading(true)


        axios.delete(`${BaseUrl}/admin/contact-query/${del._id}`, { headers })
            .then((res) => {
                // console.log("ress", res)
                if (res.data.is_success == "1") {
                    toast.success("Deleted Successfully");
                    handelget();
                }
            })
            .catch((error) => {
                console.error("Error deleting category:", error);
            }).finally(() => {
                setloading(false)
            })
    };
    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <h3>Enquiry List</h3>
                    </div>
                    <div className="col-md-12">
                        <div className="col-md-12 tableview">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                    pageSize={[]} // Set the page size to the total number of rows
                                    pagination={false}     // Disable pagination
                                />
                            </Box>
                        </div>
                    </div>
                </>

            } />
        </>
    )
}

export default ContactUs
