import React, { useEffect, useState } from 'react';
import Sidebaar from '../Components/Sidebar';
import { Form, FormGroup } from 'react-bootstrap';
import { BaseUrl } from '../Url';
import { getdata, post, putdata } from '../Api/Api';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

function ContactPage() {
    const [data, setdata] = useState("")
    const [formData, setFormData] = useState({
        title: '',
        type: '',
        media_value: ''
    });

    let token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const [editid, seteditid] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let res;
        if (editid) {
            const updatedFormData = { ...formData, media_id: editid };
            res = await putdata('admin/contact-media', updatedFormData, headers)
        } else {
            res = await post('admin/contact-media', formData, headers)
        }

        // Handle response
        // console.log(res)
        if (res.errors) {
            res.errors.map((item) => {
                return toast.error(`${item.msg} in ${item.path}`)
            })
        }
        if (res.is_success == "1") {
            // Data successfully submitted
            toast.success(res.message)
            handledata()
            // Reset form fields
            setFormData({
                title: '',
                type: '',
                media_value: ''
            });
        } else {
            // Error occurred
            console.error('Error submitting data');
        }
    };


    const handledata = async () => {
        const res = await getdata("admin/contact-media", headers)
        setdata(res.data)
    }
    useEffect(() => {
        handledata()
    }, [])
    const getRowId = (row) => row._id
    const columns = [

        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,

        },
        {
            field: "media_value",
            headerName: "Media Value",
            flex: 1,

        },

        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn custombtn' onClick={(e) => handleedit(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>
                    </>
                )
            }

        },




    ]

    const handleedit = async (editdata) => {
        seteditid(editdata._id)
        setFormData({
            title: editdata.title,
            type: editdata.type,
            media_value: editdata.media_value
        });
    }


    return (
        <>
            <Sidebaar content={
                <>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-4">
                                <FormGroup>
                                    <label htmlFor="title">Title</label>
                                    <input
                                        type="text"
                                        className='form-control shadow-none'
                                        id="title"
                                        name="title"
                                        value={formData.title}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup>
                                    <label htmlFor="type">Type</label>
                                    <input
                                        type="text"
                                        className='form-control shadow-none'
                                        id="type"
                                        name="type"
                                        value={formData.type}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup>
                                    <label htmlFor="media_value">Media Value</label>
                                    <input
                                        type="text"
                                        className='form-control shadow-none'
                                        id="media_value"
                                        name="media_value"
                                        value={formData.media_value}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <button type="submit " className="btn mt-3 bg-green text-white">Submit</button>
                    </Form>
                    <div className="col-md-12 tableview">
                        <Box>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                                pageSize={data.length} // Set the page size to the total number of rows
                                pagination={false}     // Disable pagination
                            />
                        </Box>
                    </div>
                </>
            } />
        </>
    );
}

export default ContactPage;
