import React from 'react'

function TitleComp(props) {
    return (
        <>
            <div className="col-md-12">
                <div className="text-center">
                    <h3>{props.title}</h3>
                </div>
            </div>
        </>
    )
}

export default TitleComp
