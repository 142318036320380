import React, { useEffect, useState } from 'react'
import Sidebaar from '../Components/Sidebar'
import axios from 'axios'
import { BaseUrl, imgurl } from '../Url'
import logo from "../assets/img/logo.png"
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import TitleComp from '../Components/TitleComp'
import Loader from '../Components/Loader'

function BatchList() {

    const [loading, setloading] = useState(false)

    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [data, setdata] = useState([])
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const handleget = () => {
        setloading(true)
        axios.get(`${BaseUrl}/product/batch`, { headers }).then((res) => {
            console.log(res)
            setdata(res.data.data)
            if (res.data.success == "1") {
                setloading(false)
            }
        })
    }


    useEffect(() => {
        handleget()
    }, [])


    const handleinfo = (e, product) => {
        e.preventDefault()

        if (product.length <= 0) {
            toast.error("Product is not found")
        } else {
            navigate('/batchlist/product', { state: product })
        }
    }
    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <TitleComp title="Batch List" />
                    <div className="table-responsive tableview customtable">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Batch Id</th>

                                    <th>User </th>
                                    <th>Email </th>



                                    <th>View Products</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item) => (
                                    <tr key={item._id}>
                                        <td><img
                                            src={`${imgurl}/${item.user?.profile_image}`}
                                            onError={(e) => {
                                                e.target.onerror = null; // prevent infinite loop
                                                e.target.src = logo; // replace with your error image URL
                                            }}
                                            alt=""
                                            style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }}
                                        /></td>

                                        <td>{item.batch_id}</td>
                                        <td>{item?.user?.name}</td>
                                        <td>{item?.user?.email}</td>




                                        <td>
                                            <div className="position-relative" onClick={(e) => handleinfo(e, item?.products)} style={{ width: "max-content" }}>
                                                <p className='text-white p-2 mb-0 bidnotification'>{item.products.length > 10 ? `10+` : item.products.length}</p>
                                                <button className='btn custombtn'>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                </button>
                                            </div>
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </>
            } />
        </>
    )
}

export default BatchList